import iconSet from 'quasar/icon-set/mdi-v6';
import Notify from 'quasar/src/plugins/Notify.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';;

export default {
	iconSet,
	config: {
		loading: {},
		notify: {}
	},
	plugins: [Notify, LocalStorage]
};
