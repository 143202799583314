import {showErrorMessage } from '../../global/utils/errors';


export class Api {
	async request(action, message) {
		try {
			const { data } = await action;

			return data;
		} catch (exception) {
			showErrorMessage({ message, timeout: 10000 }, exception);
		}
	}
}
