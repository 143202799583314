export const getType = value => {
	const wrap = value => value.toLowerCase();

	if (value === null) {
		return 'null';
	}

	const baseType = typeof value;

	if (![ 'object', 'function' ].includes(baseType)) {
		return wrap(baseType);
	}

	const tag = value[Symbol.toStringTag];

	if (typeof tag === 'string') {
		return wrap(tag);
	}


	if (baseType === 'function' && Function.prototype.toString.call(value).startsWith('class')) {
		return wrap('class');
	}

	const className = value.constructor.name;

	if (typeof className === 'string' && className !== '') {
		return wrap(className);
	}

	return wrap(baseType);
};

export const typeIsString = value => getType(value) === 'string';
export const typeIsNumber = value => getType(value) === 'number';
export const typeIsBoolean = value => getType(value) === 'boolean';
export const typeIsArray = value => getType(value) === 'array';
export const typeIsObject = value => getType(value) === 'object';
export const typeIsFunction = value => getType(value) === 'function';
export const typeIsClass = value => getType(value) === 'class';
export const typeIsNull = value => getType(value) === 'null';
