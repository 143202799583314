<template>
	<tr v-for="(columnGroup, columnGroupIdx) in store.gridColumnGroups" :key="`head-columnGroup-${columnGroupIdx}`"
		class="columns-group">
		<th v-if="store.gridItemSelectionEnable" class="selection-column-head"></th>
		<th v-for="(group, groupIdx) in columnGroup"
			:key="`column-group-${groupIdx}`"
			:colspan="group.length"
			:class="[`text-${group.align}`, group.classes]"
			:style="group.style"
		>
			{{ group.title }}
		</th>
	</tr>
</template>

<script setup>
import { inject } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
</script>

<style lang="scss" scoped>
.columns-group {
	background-color: #f2f2f2;

	th {
		position: relative;
		padding: 6px;
		vertical-align: middle;

		&:not(:last-child) {
			border-right: 1px solid #ddd;
		}

		&:not(.selection-column-head) {
			border-bottom: 1px solid #ddd;
		}

		&.selection-column-head {
			width: 50px;
			text-align: center;
		}
	}
}
</style>
