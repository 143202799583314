<template>
	<slot v-if="hasSlot('summaryFieldsRow')"
		  name="summaryFieldsRow"
		  :summary-fields="store.gridSummaryFields"
		  :columns="store.gridColumns"
	></slot>
	<template v-else>
		<tr v-if="!isMobile" class="columns-summary-field">
			<th v-if="store.gridItemSelectionEnable"></th>
			<th v-for="column in store.gridColumns"
				:key="`summary-field-cell-${column.name}`"
				:style="column.style"
				:class="`text-${column.titleAlign}`"
			>
				<slot v-if="hasSlot(`summaryFieldCell${column.name}`)"
					  :name="`summaryFieldCell${column.name}`"
					  :value="store.gridSummaryFields[column.field]"
					  :summary-fields="store.gridSummaryFields"
					  :column="column"
				></slot>
				<template v-else>
					{{ column.summary(store.gridSummaryFields[column.field]) }}
				</template>
			</th>
		</tr>
		<tr v-else-if="hasMobileSummaryField">
			<th v-if="store.gridItemSelectionEnable"></th>
			<th colspan="2">
				<slot v-if="hasMobileSummarySlot"
					  name="summaryFieldMobileCell"
					  :value="store.gridSummaryFields[mobileColumn.field]"
					  :summary-fields="store.gridSummaryFields"
					  :column="mobileColumn"
				></slot>
				<template v-else>
					{{ mobileColumn.summary(store.gridSummaryFields[mobileColumn.field]) }}
				</template>
			</th>
		</tr>
	</template>
</template>

<script setup>
import { useSlots, inject, computed } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const slots = useSlots();

const hasSlot = slotName => !!slots[slotName];

const { isMobile } = store.platform;
const mobileColumn = computed(() => isMobile ? store.gridColumns.find(column => column) : null);
const hasMobileSummarySlot = hasSlot('summaryFieldMobileCell');
const hasMobileSummaryField = computed(() => isMobile && (hasMobileSummarySlot || !!store.gridSummaryFields[mobileColumn.value.field]));
</script>

<style lang="scss" scoped>
tr {
	th {
		position: relative;
		padding: 6px;
		vertical-align: middle;

		&:not(:last-child) {
			border-right: 1px solid #ddd;
		}
	}

	&.columns-summary-field {
		background-color: #f8f8f8;

		th {
			border-bottom: 1px solid #ddd;
		}
	}
}
</style>
