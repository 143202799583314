<template>
	<div v-if="gridStore.gridColumns" :class="[`grid-area`, { 'disable': gridStore.progress.loading }]">
		<slot name="before"></slot>
		<div ref="gridBox"
			 class="grid"
			 tabindex="-1"
			 @keydown="keyboardNavigationAction"
		>
			<template v-if="!isMobile && (gridStore.paginationPositionTop || gridStore.paginationPositionBoth)">
				<slot v-if="hasSlot('gridFooter')" name="gridFooter" :store="gridStore"></slot>
				<GridFooter
						v-else
						:report="gridReport"
						@refresh-grid="refreshGrid"
						@pagination="changePaginationPageHandler"
						@show-rows="changeShowRowsItemsHandler"
				>
					<template v-for="name in Object.keys(slots)" #[name]="slotData">
						<slot :name="name" v-bind="slotData"></slot>
					</template>
				</GridFooter>
			</template>

			<slot v-if="hasSlot('gridHeader')" name="gridHeader" :store="gridStore"></slot>
			<GridHeader v-else @apply-filters="applyGridFilters" @clear-filters="clearAllGridFilters">
				<template v-for="name in Object.keys(slots)" #[name]="slotData">
					<slot :name="name" v-bind="slotData"></slot>
				</template>
			</GridHeader>

			<div class="grid-content">
				<slot name="start"></slot>

				<GridTable
						:separator="separator"
						@all-selected="gridItemsAllSelectionHandler"
						@sort="changeSortColumnHandler"
						@select="selectGridItemHandler"
						@apply-filters="applyGridFilters"
				>
					<template v-for="name in Object.keys(slots)" #[name]="slotData">
						<slot :name="name" v-bind="slotData"></slot>
					</template>
				</GridTable>

				<slot name="end"></slot>
			</div>

			<template v-if="gridStore.paginationPositionBottom || gridStore.paginationPositionBoth">
				<slot v-if="hasSlot('gridFooter')" name="gridFooter" :store="gridStore"></slot>
				<GridFooter v-else
							:report="gridReport"
							@refresh-grid="refreshGrid"
							@pagination="changePaginationPageHandler"
							@show-rows="changeShowRowsItemsHandler"
				>
					<template v-for="name in Object.keys(slots)" #[name]="slotData">
						<slot :name="name" v-bind="slotData"></slot>
					</template>
				</GridFooter>
			</template>
		</div>

		<slot name="after"></slot>
	</div>
</template>

<script setup>
	// TODO
	// use date-input component in grid filters when it will be ready
	// mobile header sticky
  // Grid store name to Symbol (remove makeSeparateStore)

	// TODO optionals
	// gridCogConfig: Sticky header
	// gridCogConfig: Sticky column
	// gridCogConfig: Visible-columns
	// gridCogConfig: Virtual-scroll

	import { useSlots, computed, ref, provide, onMounted } from 'vue';

	import { useGridStore, defaultColumnFilterSettings } from './store';
	import GridApiController from './api';
	import { STORE_KEY } from './const';

	import GridHeader from './components/GridHeader';
	import GridTable from './components/GridTable';
	import GridFooter from './components/GridFooter';


	import { PREV_NAV_CODE, NEXT_NAV_CODE, SELECT_KEY_CODE, DETAILS_KEY_CODE } from './const/keyboard.js';
	import formatGridColumnFilterValue from './utils/formatters/column-filter';

	const props = defineProps({
		api: {
			type: [ Object, null ],
			default: null
		},
		columns: {
			type: Array,
			required: true
		},
		columnGroups: {
			type: [ Array, null ],
			default: null // Example [ [{ title: 'Common', length: 5 }], [{ title: 'Test 2', length: 2 }, { title: 'Test 2', length: 2 }, { title: 'Test 1', length: 1 }] ]
		},
		itemIdKey: {
			type: String,
			default: 'Id'
		},
		items: {
			type: [ Array, null ],
			default: null
		},
		pagination: {
			type: [ Object, null ],
			default: null
		},
		gridId: {
			type: String,
			required: true
		},
		predefinedFilters: {
			type: [ Object ],
			default: () => ({})
		},
		separator: {
			type: String,
			default: 'horizontal' // vertical cell none
		},
		skeletonCount: {
			type: Number,
			default: 10
		},
		// Boolean (can be used as grid single props <Grid remote-fields-config disable-local-config... >)
		remoteFieldsConfig: {
			type: Boolean,
			default: false
		},
		disableLocalConfig: {
			type: Boolean,
			default: false
		},
		showReportLine: {
			type: Boolean,
			default: true
		},
		itemSelectionEnable: {
			type: Boolean,
			default: true
		},
		stickyHeader: {
			type: Boolean,
			default: false
		},
		stickyColumn: {
			type: Boolean,
			default: false
		}
	});

	const slots = useSlots();
	const emit = defineEmits(['selection', 'clear-all-filters']);

	const apiControl = ref(null);
	const gridBox = ref(null);

	const storeName = props.gridId || 'Default';
	const gridStore = useGridStore(storeName);

	const hasApiConfig = computed(() => props.api && props.api.method && props.api.url);
	const gridReport = computed(() => props.showReportLine && apiControl.value && !!apiControl.value.lastRequestDatetime ? apiControl.value.lastRequestDatetime : null);

	const { isMobile } = gridStore.platform;

	const hasSlot = slotName => !!slots[slotName];
	const setGridId = (id = props.gridId) => gridStore.updateGridId(id);
	const setLocalConfigDisableStatus = (state = props.disableLocalConfig) => gridStore.setLocalConfigDisable(state);
	const setGridColumns = (columns = props.columns) => gridStore.updateGridColumns(columns);
	const setGridItems = (items = props.items) => gridStore.updateGridItems(items);
	const setGridItemIdKey = (id = props.itemIdKey) => !gridStore.gridItemIdKey && gridStore.updateGridItemIdKey(id);
	const setGridStickyHeader = (state = props.stickyHeader) => gridStore.updateGridStickyHeader(state);
	const setGridStickyColumn = (state = props.stickyColumn) => gridStore.updateGridStickyColumn(state);

	const setGridColumnGroups = () => {
		const { columnGroups } = props;

		if (columnGroups) {
			gridStore.updateGridColumnGroups(columnGroups);
		}
	};
	const setFilterShow = () => {
		const { filtersShow } = gridStore.getLocalGridConfig();

		if (filtersShow) {
			gridStore.updateFiltersShow(filtersShow);
		}
	};
	const setGridPagination = (initialPagination = props.pagination) => {
		if (!gridStore.gridPagination) {
			const { itemsPerPage } = gridStore.getLocalGridConfig();
			const localPagination = itemsPerPage ? { itemsPerPage } : {};

			gridStore.updateGridPagination({ ...initialPagination, ...localPagination });
		}
	};
	const setGridSkeletonCount = (count = props.skeletonCount) => gridStore.updateGridSkeletonCount(count);
	const showItemsProgress = values => gridStore.updateProgressItems(values);
	const resetItemsProgress = () => gridStore.updateProgressItems([]);
	const resetHighlightItems = () => gridStore.updateStateBlink(null);
	const highlightItems = idList => {
		gridStore.updateStateBlink(idList);

		setTimeout(resetHighlightItems, 1500);
	};
	const setItemSelectionEnable = (state = props.itemSelectionEnable) => gridStore.updateItemSelectionEnable(state);
	const resetFirstGridRequest = () => {
		gridStore.resetFirstGridRequest();
	};
	const getFilterModel = column => column.filter
			? column.filter.model
					? column.filter.model
					: column.filter.modelRangeFrom && column.filter.modelRangeTo
							? { modelRangeFrom: column.filter.modelRangeFrom, modelRangeTo: column.filter.modelRangeTo }
							: null
			: null;
	const formatGridColumnFilter = (model, column) => model && typeof model === 'object'
			? Array.isArray(model)
					? {[column.field]: model}
					: model.modelRangeFrom && model.modelRangeTo
							? { [`${column.field}_from`]: formatGridColumnFilterValue(model.modelRangeFrom, { ...defaultColumnFilterSettings, ...column.filter }, true), [`${column.field}_to`]: formatGridColumnFilterValue(model.modelRangeTo, { ...defaultColumnFilterSettings, ...column.filter }) }
							: { [column.field]: model.value}
			: {[column.field]: model};
	const getInitialFilters = (columns = props.columns) => {
		const filters = columns.reduce((acc, column) => {
			const model = getFilterModel(column);

			return model ? {...acc, ...formatGridColumnFilter(model, column)} : acc;
		}, {});

		return filters || null;
	};
	const fetchGridItems = async () => {
		if (!gridStore.gridColumns) {
			setGridColumns();
		}

		const initialFilters = getInitialFilters();

		if (gridStore.state.firstGridRequest && initialFilters) {
			apiControl.value.updateFilters(initialFilters);
			// setGridColumns(); // uncomment if Commission report admin has error in grid
		}

		gridStore.resetGridError();
		gridStore.updateProgressLoading(true);

		try {
			const { items, summary, total } = await apiControl.value.fetchList();

			gridStore.$patch({
				gridItems: items,
				gridSummaryFields: summary,
				gridItemsTotal: Number(total),
				state: {
					firstGridRequest: false
				}
			});
		} finally {
			gridStore.updateProgressLoading(false);
		}
	};
	const fetchGridFields = async () => {
		gridStore.updateProgressLoading(true);

		try {
			const { fields } = await apiControl.value.fetchFields();

			gridStore.updateGridFields(fields);
		} finally {
			await fetchGridItems();
		}
	};
	const createdGridApiController = () => {
		if (hasApiConfig.value) {
			const { url, method } = props.api;

			const { itemsPerPage, currentPage } = gridStore.gridPagination;
			const { predefinedFilters } = props;
			const showGridError = gridStore.showGridError;

			apiControl.value = new GridApiController({ url, method }, { itemsPerPage, currentPage, predefinedFilters }, showGridError);

			const initialFetch = props.remoteFieldsConfig && !gridStore.gridFields ? fetchGridFields : fetchGridItems ;

			initialFetch();
		} else {
			gridStore.showGridError('No Api config present.');
		}
	};
	const receiveItems = () => gridStore.hasPropsItems ? setGridItems() : createdGridApiController();
	const selectGridItemHandler = (item) => {
		gridStore.updateItemsAllSelected();

		emit('selection', item);
	};
	const selectAllGridItems = () => {
		const ids = gridStore.gridItems.map(item => gridStore.getItemId(item));

		gridStore.updateItemsSelected(ids);
	};
	const resetGridItemsSelection = () => {
		gridStore.updateItemsAllSelected(false);
		gridStore.updateItemsSelected([]);
	};
	const refreshGrid = () => {
		resetGridItemsSelection();
		fetchGridItems();
	};
	const gridItemsAllSelectionHandler = isChecked => {
		isChecked
			? gridStore.hasSelectedItems ? resetGridItemsSelection() : selectAllGridItems()
			: resetGridItemsSelection();
	};
	const changePaginationPageHandler = () => {
		apiControl.value.updateCurrentPage(gridStore.gridPagination.currentPage);

		gridStore.updateLocalGridConfig();
		refreshGrid();
	};
	const changeShowRowsItemsHandler = () => {
		gridStore.updatePaginationCurrentPage(1);

		apiControl.value.updateCurrentPage(gridStore.gridPagination.currentPage);
		apiControl.value.updateItemsPerPage(gridStore.gridPagination.itemsPerPage);

		gridStore.updateLocalGridConfig();
		refreshGrid();
	};
	const changeSortColumnHandler = column => {
		if (!column.sortable) {
			return;
		}

		const { field } = column;
		const sortOrderCurrentIndex = gridStore.columnSortOrderMap.findIndex(order => order === column.state.sortOrder);
		const nextIndex = sortOrderCurrentIndex + 1;
		const sortOrder = gridStore.columnSortOrderMap[nextIndex === gridStore.columnSortOrderMapLength ? 0 : nextIndex];

		gridStore.resetColumnsSortOrder();
		apiControl.value.updateFilters(gridStore.gridColumnFilters); // apply changed filters before sorting
		apiControl.value.updateSorting({ field, sortOrder });

		column.state.sortOrder = sortOrder;

		refreshGrid();
	};
	const updateApiControlRequest = params => {
		apiControl.value.updateRequest(params);

		refreshGrid();
	};
	const applyGridFilters = externalFilters => {
		const filters = externalFilters ? { ...gridStore.gridColumnFilters, ...externalFilters } : gridStore.gridColumnFilters;

		apiControl.value.updateFilters(filters);

		refreshGrid();
	};
	const applyGridPredefinedFilters = filters => apiControl.value.updatePredefined(filters);
	const setGridFilters = filters => Object.keys(filters).forEach(key => gridStore.setColumnFilter(key, filters[key]));
	const resetGridItems = () => setGridItems([]);
	const clearAllGridFilters = () => {
		if (!gridStore.gridColumns) {
			return;
		}

		gridStore.resetColumnsFilters();
		apiControl.value.updateFilters(gridStore.gridColumnFilters);

		emit('clear-all-filters');

		refreshGrid();
	};
	const keyboardNavigationAction = ({ target, code }) => {
		const keyCodes = [
			SELECT_KEY_CODE,
			PREV_NAV_CODE,
			NEXT_NAV_CODE,
			DETAILS_KEY_CODE
		];

		if (!keyCodes.includes(code)) {
			return;
		}

		const action = {
			[SELECT_KEY_CODE]: gridStore.toggleHighlightedItem,
			[PREV_NAV_CODE]: gridStore.prevHighlightedItem,
			[NEXT_NAV_CODE]: gridStore.nextHighlightedItem,
			[DETAILS_KEY_CODE]: () => {
				const link = target.querySelector('.highlighted a');

				link && link.click();
			}
		};

		action[code]();
	};

	defineExpose({
		gridStore,
		apiControl,
		selectAllGridItems,
		resetGridItemsSelection,
		resetFirstGridRequest,
		setGridColumns,
		setGridFilters,
		refreshGrid,
		showItemsProgress,
		resetItemsProgress,
		resetHighlightItems,
		highlightItems,
		updateApiControlRequest,
		applyGridFilters,
		applyGridPredefinedFilters,
		clearAllGridFilters,
		resetGridItems
	});

	provide(STORE_KEY, storeName);

	onMounted(() => {
		if (gridBox.value) {
			gridBox.value.focus();
		}
	});

	setGridItemIdKey();
	setGridId();
	setGridStickyHeader();
	setGridStickyColumn();
	setLocalConfigDisableStatus();
	setGridColumnGroups();
	setGridPagination();
	setFilterShow();
	setGridSkeletonCount();
	setItemSelectionEnable();
	receiveItems();
</script>

<script>
	export * from '@utils/format';
	export * from './utils';
</script>

<style lang="scss" scoped>
.grid-area {
	&.disable * {
		pointer-events: none;
	}

	.grid {
		&:focus-visible {
			outline: none;
		}
	}

	.grid-content {
		display: flex;
		//overflow: auto;
	}
}
</style>
