export const APP_HEADER = {
	SET: 'EventAppHeaderSetAll',
	RESET: 'EventAppHeaderResetAll',

	SET_TITLE: 'EventAppHeaderSetTitle',
	RESET_TITLE: 'EventAppHeaderResetTitle',

	SET_BACK_LINK: 'EventAppHeaderSetBackLink',
	RESET_BACK_LINK: 'EventAppHeaderResetBackLink',

	SET_APP_ICON: 'EventAppHeaderSetAppIcon',
	RESET_APP_ICON: 'EventAppHeaderResetAppIcon',

	SET_OPTIONS: 'EventAppHeaderSetOptions',
	RESET_OPTIONS: 'EventAppHeaderResetOptions'
};

export default APP_HEADER;
