// import axios from 'axios';
import axios from '@/global/plugins/axios';
import dateUtils from 'quasar/src/utils/date.js';;
import { getRequestMessage } from '@utils/errors';

export default class GridApiController {
	constructor({ url, method }, { itemsPerPage, currentPage, predefinedFilters }, showGridError) {
		this.url = url;
		this.method = method || 'GET';

		this.itemsPerPage = itemsPerPage;
		this.currentPage = currentPage;
		this.predefinedFilters = this.parseFilters(predefinedFilters);
		this.errorHandler = showGridError;

		this.sorting = {};
		this.filters = {};
		this.lastRequestDatetime = null;
	}

	get payload() {
		const count = { count: this.itemsPerPage };
		const from = this.currentPage > 1 ? { from: (this.itemsPerPage * (this.currentPage - 1)) + 1 } : {};
		const sorting = this.sorting ? {
			order_by: this.sorting.field,
			order_direction: this.sorting.sortOrder
		} : {};

		return { ...count, ...from, ...sorting, ...this.filters, ...this.predefinedFilters };
	}

	get isGetMethod() {
		return this.method.toLowerCase() === 'get';
	}

	parseFilters(filters) {
		return Object.keys(filters).reduce((acc, key) => filters[key] !== null ? {
			...acc,
			[key]: filters[key]
		} : acc, {});
	}

	updateItemsPerPage(value) {
		this.itemsPerPage = value;
	}

	updateCurrentPage(value) {
		this.currentPage = value;
	}

	updateSorting({ field, sortOrder }) {
		this.sorting = sortOrder ? { field, sortOrder } : null;
	}

	updatePredefined(filters) {
		this.predefinedFilters = this.parseFilters(filters);
	}

	updateFilters(filters) {
		this.filters = this.isGetMethod ? Object.keys(filters).reduce((acc, key) => {
			const value = filters[key];
			const target = Array.isArray(value) ? { [key]: value.toString() } : { [key]: value };

			return { ...acc, ...target };
		}, {}) : filters;
	}

	updateRequest({ url, method }) {
		this.url = url || this.url;
		this.method = method || this.method;
	}

	setRequestDatetime() {
		const timestamp = Date.now();

		this.lastRequestDatetime = {
			date: dateUtils.formatDate(timestamp, 'MM/DD/YYYY'),
			time: dateUtils.formatDate(timestamp, 'hh:mm:ss A')
		};
	}

	async request(url, method, payload) {
		const payloadKey = this.isGetMethod ? 'params' : 'data';
		const { data } = await axios({ url, method, [payloadKey]: payload });

		this.setRequestDatetime();

		return data;
	}

	async fetchList() {
		try {
			return await this.request(this.url, this.method, this.payload);
		} catch (error) {
			const xhrMessage = getRequestMessage(error);

			this.errorHandler(`Can't get grid list. <br> ${xhrMessage}`);
		}
	}

	async fetchFields() {
		try {
			return await this.request(this.url, this.method, { _config: true });
		} catch (error) {
			const xhrMessage = getRequestMessage(error);

			this.errorHandler(`Can't get grid fields config. ${xhrMessage}`);
		}
	}
}
