<template>
	<tbody>
	<tr>
		<td :colspan="store.totalColumnsLength" class="grid-error-message">
			<q-banner rounded class="bg-red-2" v-html="store.state.gridError" />
		</td>
	</tr>
	</tbody>
</template>

<script setup>
import { inject } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
</script>

<style lang="scss" scoped>
td {
	vertical-align: initial;
	padding: 6px;
}
</style>
