
import {formatters} from '@utils/format';

const contextRunner = (val, context) => val !== null && val !== '' ? context() : val;

const gridColumnFilterTypes = {
	Boolean: val => contextRunner(val, () => Boolean(val)),
	Number: val => contextRunner(val, () => Number(val)),
	Decimal: val => contextRunner(val, () => Number(val)),
	Float: val => contextRunner(val, () => Number(val)),
	Int32: val => contextRunner(val, () => Number(val)),
	Int64: val => contextRunner(val, () => Number(val)),
	Guid: val => contextRunner(val, () => val.toString()),
	String: val => contextRunner(val, () => val.toString()),
	DateTime: (val, dateParams) => contextRunner(val, () => formatters.date(val, dateParams)),
	Date: (val, dateParams) => contextRunner(val, () => formatters.date(val, dateParams)),
	Time: (val, dateParams) => contextRunner(val, () => formatters.date(val, dateParams)),
	// definitions types
	boolean: val => contextRunner(val, () => Boolean(val)),
	guid: val => contextRunner(val, () => val.toString()),
	text: val => contextRunner(val, () => val.toString()),
	date: (val, dateParams) => contextRunner(val, () => formatters.date(val, dateParams)),
	number: val => contextRunner(val, () => Number(val)),
	money: val => contextRunner(val, () => Number(val))
};

const formatGridColumnFilterValueByDataType = (value, filter, ignoreDateRangeTo = false) => {
	const isDataType = ['DateTime', 'Date', 'Time'].includes(filter.data);

	return isDataType
			? gridColumnFilterTypes[filter.data](value, {
				initialFormat: filter.dateFormat,
				outputFormat: !ignoreDateRangeTo && value === filter.modelRangeTo ? 'YYYY-MM-DDT23:59:59' : 'YYYY-MM-DDT00:00:00'
			})
			: gridColumnFilterTypes[filter.data]
				? gridColumnFilterTypes[filter.data](value)
				: (gridColumnFilterTypes['String'](value), console.warn(`Grid fields has unknown filter type: ${filter.data}`))
		;
};

const formatGridColumnFilterValue = (data, filter, ignoreDateRangeTo = false) => {
	return data && typeof data === 'object'
		? Array.isArray(data)
			? data.map(item => formatGridColumnFilterValueByDataType(item.value, filter, ignoreDateRangeTo))
			: formatGridColumnFilterValueByDataType(data.value, filter, ignoreDateRangeTo)
		: formatGridColumnFilterValueByDataType(data, filter, ignoreDateRangeTo);
};

export default formatGridColumnFilterValue;
