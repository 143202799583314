<template>
	<q-page>
		<Header v-bind="$data.$appHeader" ref="header" />

		<div class="app-content">
			<q-tabs
					class="bg-grey-2 text-grey-7 crm-tabs"
					active-color="primary"
					indicator-color="primary"
					align="left"
			>
				<q-route-tab name="Log" to="/log" label="Log" exact />
				<q-route-tab name="Settings" to="/settings" label="Settings" exact />

				<template v-if="!Platform.is.mobile">
					<q-route-tab name="Manufacturers" to="/manufacturers" label="Manufacturers" exact />
					<q-route-tab name="Products" to="/products" label="Products" exact />
					<q-route-tab name="Batch Action" to="/batch-action" label="Batch Action" exact />
					<q-route-tab name="Tasks" to="/tasks" label="Tasks" exact />
				</template>
				<q-btn-dropdown v-else auto-close stretch flat label="More...">
					<q-list>
						<q-item to="/manufacturers" clickable >
							<q-item-section>Manufacturers</q-item-section>
						</q-item>
						<q-item to="/products" clickable >
							<q-item-section>Products</q-item-section>
						</q-item>
						<q-item to="/batch-action" clickable>
							<q-item-section>Batch Action</q-item-section>
						</q-item>
						<q-item to="/tasks" clickable>
							<q-item-section>Tasks</q-item-section>
						</q-item>
					</q-list>
				</q-btn-dropdown>
			</q-tabs>
			<q-space />
			<div class="q-mt-sm">
				<router-view />
			</div>
			<q-resize-observer :debounce="100" @resize="onContentResize" />
		</div>
	</q-page>
</template>

<script setup>
import {inject, ref} from 'vue';
import { Platform } from 'quasar';
import Header from '@components/app-header';

const header = ref(null);
const iframeSyncClient = inject('iframeSyncClient');

const onContentResize = (size) => {
	const { height } = size;
	const { clientHeight } = header.value.$el;
	const heightHeader = clientHeight || 56;
	const styles = { height: `${height + heightHeader}px` };

	iframeSyncClient.sendMessage({ type: 'style', styles });
};
</script>
