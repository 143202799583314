<template>
<tbody v-for="(item, idx) in store.gridItems" :key="`item-${store.getItemId(item)}-${idx}`">
	<slot v-if="hasSlot('itemRow')"
		  name="itemRow"
		  :item="item"
		  :columns="store.gridColumns"
		  :index="idx"
	></slot>
	<template v-else>
		<tr :class="{
				'blink': store.isGridItemBlink(store.getItemId(item)),
				'selected': store.isGridItemSelected(store.getItemId(item)),
				'expanded': store.isGridItemExpanded(store.getItemId(item)),
				'highlighted': store.isGridItemHighlighted(store.getItemId(item))
			}"
			@click="selectItem($event, store.getItemId(item), idx)"
		>
			<td v-if="store.gridItemSelectionEnable"
				:class="['selection-column-body', { 'sticky-column': store.isStickyColumn }]">
				<slot v-if="hasSlot('itemSelector')" name="itemSelector" :store="store" :item="item"></slot>
				<template v-else>
					<q-spinner v-if="store.isGridItemInProgress(store.getItemId(item))" color="primary" size="24px"
							   class="q-ma-sm" />
					<q-checkbox v-else
								v-model="store.gridItemsSelectedIds"
								:val="store.getItemId(item)"
								dense
								@click="triggerSelect(item, idx)"
					/>
				</template>
			</td>
			<template v-if="!mobileTargetColumn">
				<td v-for="(column, columnIdx) in store.gridColumns"
					:key="`${column.name}${store.getItemId(item)}`"
					:class="[`text-${column.align}`, {
						'sorted': !!column.sortOrder,
						'sticky-column': store.isStickyColumn && !columnIdx,
						'has-selector': store.isStickyColumn && store.gridItemSelectionEnable
					}]"
				>
					<slot
							v-if="hasSlot(`cell${column.name}`)"
							:name="`cell${column.name}`"
							:value="item[column.field]"
							:item="item"
							:index="idx"
					></slot>
					<template v-else>
						{{ column.format(item[column.field]) }}
					</template>
				</td>
			</template>
			<template v-else>
				<td :class="[`text-${mobileTargetColumn.align}`, { 'sorted': !!mobileTargetColumn.sortOrder,'has-selector': store.gridItemSelectionEnable }]">
					<slot
							v-if="hasSlot('cellMobileColumn')"
							:name="'cellMobileColumn'"
							:value="item[mobileTargetColumn.field]"
							:item="item"
							:index="idx"
					></slot>
					<slot v-else-if="hasSlot(`cell${mobileTargetColumn.name}`)"
						  :name="`cell${mobileTargetColumn.name}`"
						  :value="item[mobileTargetColumn.field]"
						  :item="item"
						  :index="idx"
					></slot>
					<template v-else>
						{{ mobileTargetColumn.format(item[mobileTargetColumn.field]) }}
					</template>
				</td>
				<td class="text-right">
					<q-btn
							size="sm"
							dense outline
							class="q-btn--outline-gray q-mr-sm"
							:icon="store.isGridItemExpanded(store.getItemId(item)) ? 'mdi-minus' : 'mdi-plus'"
							@click="store.updateStateExpanded(store.getItemId(item))"
					/>
				</td>
			</template>
		</tr>
		<tr v-if="isMobile && store.isGridItemExpanded(store.getItemId(item))">
			<td colspan="3">
				<q-list dense>
					<q-item
							v-for="mobileColumn in store.gridColumns"
							:key="`${mobileColumn.name}${store.getItemId(item)}`"
							class="flex justify-between"
					>

						<q-item-section class="col-4">
							<q-item-label caption lines="2" class="col">{{ mobileColumn.title }}</q-item-label>
						</q-item-section>
						<q-item-section>
							<q-item-label class="col">
								<slot v-if="hasSlot(`cellMobile${mobileColumn.name}`)"
									  :name="`cellMobile${mobileColumn.name}`"
									  :value="item[mobileColumn.field]"
									  :item="item"
									  :index="idx"
								></slot>
								<slot v-else-if="hasSlot(`cell${mobileColumn.name}`)"
									  :name="`cell${mobileColumn.name}`"
									  :value="item[mobileColumn.field]"
									  :item="item"
									  :index="idx"
								></slot>
								<template v-else>
									{{ mobileColumn.format(item[mobileColumn.field]) }}
								</template>
							</q-item-label>
						</q-item-section>
					</q-item>
				</q-list>

				<q-separator />
			</td>
		</tr>
	</template>
</tbody>
</template>

<script setup>
import { useSlots, inject, computed } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const slots = useSlots();
const emit = defineEmits([ 'select' ]);

const { isMobile } = store.platform;
const mobileTargetColumn = computed(() => isMobile ? store.gridColumns.find(column => column) : null);

const hasSlot = slotName => !!slots[slotName];
const triggerSelect = (item, idx) => {
	emit('select', item);

	store.setLastUsedIdx(idx);
};

const selectItem = ({ ctrlKey, metaKey, shiftKey }, itemId, listIdx) => {
	if (ctrlKey || metaKey) {
		store.updateGridItemsSelectedIds(itemId, listIdx);
	} else if (shiftKey) {
		store.rangeGridItemsSelectedIds(itemId, listIdx);
	} else {
		store.setItemHighlighted(itemId);
	}
};
</script>

<style lang="scss" scoped>
@keyframes blinker {
	50% {
		background-color: rgba(255, 235, 160, .75);
	}
}

tbody {
	tr {
		td {
			vertical-align: initial;
			padding: 6px;

			&.sorted {
				background-color: #f6f6f6;
			}

			&.selection-column-body {
				width: 50px;
				text-align: center;
			}

			&.sticky-column {
				position: sticky;
				z-index: 1;
				left: 0;
				background-color: #f2f2f2;
				border-right: 1px solid #ddd;

				&.has-selector {
					left: 33px;
				}
			}
		}

		&.blink {
			animation: blinker .3s linear infinite;
		}

		&.selected {
			background-color: #eff5ff;

			td.sorted {
				background-color: #eff5ff;
			}
		}

		&.expanded {
			background-color: #e6f4ea;
		}

		&:hover {
			background-color: #fafafa;

			td.sorted {
				background-color: #fafafa;
			}
		}

		&.highlighted {
			background-color: #fffae5;

			td.sorted {
				background-color: #fffae5;
			}
		}

		&:focus-visible {
			outline: none;
		}
	}

	&:first-of-type {
		tr {
			td {
				padding: 8px 6px 6px;
			}
		}
	}
}
</style>
