<template>
	<div class="manufacturers-list-tab-content">
		<Grid v-if="!!gridConfig" ref="grid" v-bind="gridConfig" >
			<template #columnFiltermanufacturerName="{ column }">
				<SelectControl
						:filter="column.filter"
						:column="column"
						model-key="model"
						@update:model-value="changeFilter($event, 'manufacturerName')"
				>
					<template v-if="column.filter.model" #clear>
						<q-icon name="mdi-close" class="cursor-pointer" @click.stop.prevent="clearFilter(column)"/>
					</template>
					<template #no-option>
						<q-item>
							<q-item-section class="text-grey">
								No results
							</q-item-section>
						</q-item>
					</template>
				</SelectControl>
			</template>
		</Grid>
	</div>
</template>

<script>
import { ref, inject } from 'vue';

import Grid from '@components/grid';
import SelectControl from '@components/grid/components/table/filter/SelectControl';
import api from '@/api';
import { useManufacturersStore } from '@/store';

export default {
	components: {
		Grid,
		SelectControl
	},
	beforeRouteEnter(to, from, next) {
		const { serviceId } = from.params;

		if (!serviceId) {
			next();
		} else {
			next(vm => vm.highlightGridItem(serviceId));
		}
	},
	setup(props, { expose }) {
		const iframeSyncClient = inject('iframeSyncClient');
		const manufacturersStore = useManufacturersStore();
		const grid = ref(null);
		const gridConfig = ref(null);

		const clearFilter = async (column) => column.filter.model = null;

		const initGridConfig = () => {
			const url = api.fetchManufacturersGrid();

			gridConfig.value = {
				api: { url, method: 'POST' },
				itemIdKey: 'manufacturerName',
				gridId: 'ManufacturersGrid',
				itemSelectionEnable: false,
				remoteFieldsConfig: true,
				pagination: {
					itemsPerPageOptions: [10, 50, 100, 500]
				},
				columns: [
					{
						title: 'Brand',
						field: 'manufacturerName',
						filter: {
							style: 'max-width: 155px;',
							type: 'select',
							optionValue: 'name',
							optionLabel: 'name',
							options: getLookupManufacturer()
						}
					},
					{
						title: 'Count',
						field: 'count'			
					},
					{
						title: 'Not In Crm',
						field: 'notInCrm',
						width: 110,
						filter: {
							range: true
						}
					},
					{
						title: 'In Crm',
						field: 'inCrm',
						width: 110
					}
				]
			};
		};
		const getLookupManufacturer = () => {
			return manufacturersStore.manufacturers.map(manufacturer => ({
				label: manufacturer.name,
				value: manufacturer.name
			}))
		};
		const changeFilter = async (event, name) => {
			const { value } = event;
			const model = name === 'ignoreItem' ? event : value;

			grid.value.setGridFilters({[name]: { model }});
		};

		const highlightGridItem = serviceId => {
			grid.value.highlightItems([serviceId]);
		};

		const init = async () => {
			iframeSyncClient.sendMessage({ type: 'loading', state: true });

			try {
				if (!manufacturersStore.manufacturers.length) {
					const manufacturers = await api.fetchManufacturers();

					manufacturersStore.updateManufacturers(manufacturers);
				}

				initGridConfig();
			} finally {
				iframeSyncClient.sendMessage({ type: 'loading', state: false });
			}
		};

		expose({
			highlightGridItem
		});

		init();

		return {
			grid,
			gridConfig,
			changeFilter,
			clearFilter,
			getLookupManufacturer
		};
	}
};
</script>
