<template>
	<q-select :model-value="props.filter[modelKey]"
			  :options="options"
			  :placeholder="props.rangeTitle || props.filter.placeholder || props.column.title"
			  :style="props.filter.style"
			  :rules="props.filter.rules"
			  bg-color="white"
			  outlined dense options-dense
			  hide-bottom-space use-input
			  hide-selected fill-input
			  input-debounce="0"
			  @filter="filterFn"
			  @input-value="setModel"
	>
		<template v-if="props.filter[modelKey]" #append>
			<slot name="clear"></slot>
		</template>
		<template v-if="!isTextSelectMode" #no-option>
			<slot name="no-option"></slot>
		</template>
	</q-select>
</template>

<script setup>
import { computed, ref, useSlots } from 'vue';

const props = defineProps({
	modelKey: {
		type: String,
		required: true
	},
	filter: {
		type: Object,
		required: true
	},
	column: {
		type: Object,
		required: true
	},
	rangeTitle: {
		type: [ String, null ],
		default: null
	}
});

const slots = useSlots();

const isTextSelectMode = computed(() => {
	return !slots['no-option'];
});

const filterOptions = props.filter.options.map(option => ({
	label: option[props.filter.optionLabel],
	value: option[props.filter.optionValue]
}));
const options = ref(filterOptions);

const filterFn = (val, update) => {
	update(() => {
		const needle = val && val.toLowerCase();

		options.value = props.filter.options.filter(option => option.label.toLowerCase().indexOf(needle) > -1);
	});
};
const setModel = (value) => {
	const target = props.filter.options.find(option => option.label === value);
	const defaultFilterValue = isTextSelectMode.value ? value : null;

	props.filter[props.modelKey] = target || defaultFilterValue;
};
</script>
