<template>
	<div class="log-tab-content">
		<Grid v-if="!!gridConfig" ref="grid" v-bind="gridConfig">
			<template #cellActions="{ item }">
				<q-btn label="" icon="mdi-dots-vertical" unelevated round>
					<q-menu>
						<q-list>
							<q-item :to="{ name: 'task-errors', params: {taskId: item.id} }" clickable v-close-popup>
								<q-item-section>
									Show Errors
								</q-item-section>
								<q-item-section class="q-pr-none items-center" avatar>
									<q-icon name="mdi-alert-circle-outline" />
								</q-item-section>
							</q-item>
						</q-list>
					</q-menu>
				</q-btn>
			</template>
			<template #cellprogress="{ value }">
				<q-linear-progress stripe rounded size="20px" :value="(value / 100)">
					<div class="absolute-full flex flex-center">
						<q-badge color="white" text-color="accent" :label="`${value}%`" />
					</div>
				</q-linear-progress>
			</template>
			<template #cellMobileActions="{ item }">
				<div class="q-gutter-sm q-pb-sm">
					<q-btn
							unelevated no-caps outline dense
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							label="Show Errors"
							@click="showErrors(item)"
					/>
				</div>
			</template>
		</Grid>
	</div>
</template>

<script>
import { inject, ref, nextTick } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { Platform } from 'quasar';
import Grid, { formatters } from '@components/grid';
import api from '@/api';
import { useLookupsStore } from '@/store';

const GridRefreshTimeout = 5000;

export default {
	components: {
		Grid
	},
	beforeRouteEnter(to, from, next) {
		const { serviceId } = from.params;

		if (!serviceId) {
			next();
		} else {
			next(vm => vm.highlightGridItem(serviceId));
		}
	},
	setup(props, { expose }) {
		const route = useRoute();
		const iframeSyncClient = inject('iframeSyncClient');
		const lookupsStore = useLookupsStore();

		const grid = ref(null);
		const gridConfig = ref(null);
		const gridRefreshInterval = ref(null);

		const initGridConfig = () => {
			const url = api.fetchTasksGrid();

			gridConfig.value = {
				api: { url, method: 'POST' },
				itemIdKey: 'id',
				gridId: 'TasksGrid',
				remoteFieldsConfig: true,
				itemSelectionEnable: false,
				pagination: {
					itemsPerPageOptions: [10, 50, 100, 500]
				},
				columns: [
					{
						field: 'createdAt',
						title: 'Date',
						format: value => formatters.date(value),
						filter: {
							style: 'min-width: 135px;',
						}
					},
					{
						title: 'Mode',
						field: 'mode'
					},
					{
						field: 'updatedAt',
						title: 'Updated At',
						format: value => formatters.date(value),
						filter: {
							style: 'min-width: 135px;',
						}
					},
					{
						title: 'Code',
						field: 'statusCode',
						filter: {
							style: 'min-width: 100px;',
						}
					},
					{
						title: 'Status',
						field: 'status',
						filter: {
							style: 'max-width: 155px;',
							type: 'select',
							options: getLookupStatuses()
						}
					},
					{
						title: 'Count',
						field: 'totalCount'
					},
					{
						title: 'Processed',
						field: 'processed'
					},
					{
						title: 'Progress',
						field: 'progress',
						style: 'min-width: 135px;'
					},
					{
						title: Platform.is.mobile ? 'Download' : '',
						name: 'Actions',
						sortable: false,
						filterable: false
					},
				]
			};
			startGridRefreshInterval();
		};

		const startGridRefreshInterval = () => {
			if (gridRefreshInterval.value) {
				return
			}

			gridRefreshInterval.value = setInterval(updateGrid, GridRefreshTimeout);
		};
		const resetGridRefreshInterval = () => {
			if (gridRefreshInterval.value) {
				clearInterval(gridRefreshInterval.value);
				gridRefreshInterval.value = null;
			}
		}
		const getLookupStatuses = () => {
			return lookupsStore.taskStatuses.map(status => ({
				label: status,
				value: status
			}))
		};
		const highlightGridItem = serviceId => {
			grid.value.highlightItems([serviceId]);
		};
		const showErrors = row => {

			highlightGridItem(row.id);
		};
		const updateGrid = () => {
			grid.value.refreshGrid();
		};

		const init = async () => {
			const { taskId } = route.query;
			iframeSyncClient.sendMessage({ type: 'loading', state: true });

			try {
				if (!lookupsStore.taskStatuses.length) {
					const statuses = await api.fetchTaskStatuses();

					lookupsStore.updateTaskStatuses(statuses);
				}

				initGridConfig();

				if (taskId) {
					await nextTick();

					highlightGridItem(taskId);
				}

			} finally {
				iframeSyncClient.sendMessage({ type: 'loading', state: false });
			}
		};

		expose({
			highlightGridItem
		});

		init();

		onBeforeRouteLeave(() => {
			resetGridRefreshInterval();
		});

		return {
			grid,
			gridConfig,
			gridRefreshInterval,
			showErrors
		};
	}
};
</script>

<style lang="scss" scoped>
@keyframes blinker {
	50% {
		background-color: rgba(255, 235, 160, .75);
	}
}

tbody {
	tr {
		td {
			vertical-align: initial;
			padding: 6px;

			&.sorted {
				background-color: #f6f6f6;
			}

			&.selection-column-body {
				width: 50px;
				text-align: center;
			}

			&.sticky-column {
				position: sticky;
				z-index: 1;
				left: 0;
				background-color: #f2f2f2;
				border-right: 1px solid #ddd;

				&.has-selector {
					left: 33px;
				}
			}
		}

		&.blink {
			animation: blinker .3s linear infinite;
		}

		&.selected {
			background-color: #eff5ff;
		}

		&:hover {
			background-color: #fafafa;
		}

		&.highlighted {
			background-color: #fffae5;
		}

		&:focus-visible {
			outline: none;
		}
	}

	&:first-of-type {
		tr {
			td {
				padding: 8px 6px 6px;
			}
		}
	}
}
</style>

<style lang="scss">
tbody {
	.grid-item-error-code {
		background: #ffdfd4;
	}

	.grid-item-log-level-trace {
		background: #bdbebd;
	}

	.grid-item-log-level-debug {
		background: #949494;
	}

	.grid-item-log-level-warning {
		background: #ffff00;
	}

	.grid-item-log-level-error {
		background: #e34234;
	}

	.grid-item-log-level-critical {
		background: #ff033e;
	}
}
</style>
