<template>
	<tr class="columns-filters">
		<template v-if="!isMobile">
			<th v-if="store.gridItemSelectionEnable" :class="{ 'sticky-column': store.isStickyColumn }"></th>
			<th v-for="(column, idx) in store.gridColumns"
				:key="`head-filters-column-${column.name}`"
				:style="column.style"
				:class="[{
					'sticky-column': store.isStickyColumn && !idx,
					'has-selector': store.isStickyColumn && store.gridItemSelectionEnable
			}]"
			>
				<slot v-if="hasSlot(`columnFilter${column.name}`)"
					  :name="`columnFilter${column.name}`"
					  :column="column"
					  :index="idx"
				></slot>
				<Filter v-else :column="column" @apply-filters="triggersApplyFiltersEvent" />
			</th>
		</template>
		<th
				v-else-if="mobileColumn"
				:style="mobileColumn.style"
				colspan="3"
		>
			<div class="flex column q-gutter-md">
				<div v-for="filterColumn in store.gridColumns"
					 :key="`head-mobile-filters-column-${filterColumn.name}`"
					 class="mobile-filter-group"
				>
					<div class="filter-mobile-title text-caption text-start">
						{{ filterColumn.title }}
					</div>
					<Filter :column="filterColumn" @apply-filters="triggersApplyFiltersEvent" />
				</div>

			</div>
		</th>
	</tr>
</template>

<script setup>
import { computed, inject, useSlots } from 'vue';
import Filter from './Filter.vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const slots = useSlots();
const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const emit = defineEmits([ 'apply-filters' ]);

const { isMobile } = store.platform;
const mobileColumn = computed(() => isMobile ? store.gridColumns.find(column => column) : null);

const hasSlot = slotName => !!slots[slotName];
const triggersApplyFiltersEvent = () => emit('apply-filters');
</script>

<style lang="scss" scoped>
.columns-filters {
	background-color: #e6f4ea;

	th {
		position: relative;
		vertical-align: top;
		padding: 12px 6px;
		border-bottom: 1px solid #ddd;

		&.sticky-column {
			position: sticky;
			z-index: 1;
			left: 0;
			background-color: #e6f4ea;

			&.has-selector {
				left: 33px;
			}
		}

		&:not(:last-child) {
			border-right: 1px solid #ddd;
		}

		.mobile-filter-group {
			:deep(label) {
				max-width: initial !important;
			}
		}
	}
}
</style>
