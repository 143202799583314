<template>
	<div :class="['grid-header-area', { 'sticky-header': store.isStickyHeader }]">
		<slot name="headerBefore"></slot>

		<div class="grid-header q-pb-sm">
			<slot v-if="!isMobile" name="headerStart"></slot>
			<q-btn
					v-else-if="hasSlot('headerStartMobile')"
					:loading="mobileHeaderProgress"
					outline no-caps
					icon="mdi-dots-horizontal"
					text-color="black"
					class="q-btn--outline-gray"
			>
				<q-menu>
					<slot :progress="{
						start: startHeaderMobileProgress,
						stop: stopHeaderMobileProgress
					}" name="headerStartMobile" ></slot>
				</q-menu>
			</q-btn>

			<slot v-if="hasSlot('headerBatchActionsBtn')" name="headerBatchActionsBtn" :store="store"></slot>
			<q-btn-dropdown
					v-else-if="store.hasSelectedItems && hasSlot('batchActions')"
					unelevated no-caps
					:class="['batch-actions-btn', { 'sticky-btn batch-actions-btn': store.isStickyColumn }]"
					:label="!isMobile ? 'Batch Actions' : ''"
					icon="mdi-arrow-down-left"
			>
				<slot name="batchActions"></slot>
			</q-btn-dropdown>

			<slot v-if="hasSlot('headerFiltersBtn')" name="headerFiltersBtn" :store="store"></slot>
			<q-btn
					v-else unelevated no-caps
					:class="[`filter-toggle-btn`, {
						'active': store.state.filters.show,
						'sticky-btn filters-btn': store.isStickyColumn,
						'has-batch-actions': store.hasSelectedItems && hasSlot('batchActions')
					}]"
					:icon="store.state.filters.show ? 'mdi-filter-off' : 'mdi-filter'"
					:label="!isMobile ? 'Filters' : ''"
					:title="store.state.filters.show ? 'Hide filters' : 'Show filters'"
					@click="toggleFilter"
			/>

			<template v-if="store.state.filters.show">
				<slot v-if="hasSlot('headerFilterApplyBtn')" name="headerFilterApplyBtn" :store="store"></slot>
				<q-btn
						v-else :class="[`filter-toggle-btn`, {
							'sticky-btn filter-apply-btn': store.isStickyColumn,
							'has-batch-actions': store.hasSelectedItems && hasSlot('batchActions')
						}]"
						color="positive"
						icon="mdi-filter-check-outline"
						:label="!isMobile ? 'Apply' : ''"
						unelevated no-caps
						@click="applyFilters"
				/>

				<slot
						v-if="hasSlot('headerFilterClearBtn')"
						name="headerFilterClearBtn"
						:store="store"
				></slot>
				<q-btn
						v-else :class="[`filter-toggle-btn`, {
							'sticky-btn filter-clear-btn': store.isStickyColumn,
							'has-batch-actions': store.hasSelectedItems && hasSlot('batchActions')
						}]"
						icon="mdi-filter-remove-outline"
						:label="!isMobile ? 'Clear all' : ''"
						unelevated no-caps
						@click="clearFilters"
				/>
			</template>

			<slot v-if="!isMobile" name="headerPrepend"></slot>

			<q-space />

			<slot v-if="!isMobile" name="headerAppend"></slot>
			<slot v-else name="headerMobileActionsBefore"></slot>
			<slot v-if="!isMobile" name="headerEnd"></slot>
			<q-btn
					v-else-if="hasSlot('headerMobileActions')"
					:loading="mobileActionsProgress"
					outline no-caps
					icon="mdi-dots-horizontal"
					text-color="black"
					class="q-btn--outline-gray"
			>
				<q-menu>
					<slot :progress="{
						start: startMobileProgress,
						stop: stopMobileProgress
					}" name="headerMobileActions" ></slot>
				</q-menu>
			</q-btn>
		</div>

		<slot name="headerAfter"></slot>
	</div>
</template>

<script setup>
import { ref, useSlots, inject } from 'vue';
import { useGridStore } from '../store';
import { STORE_KEY } from '../const';

const emit = defineEmits([
	'apply-filters',
	'clear-filters'
]);

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const slots = useSlots();

const mobileHeaderProgress = ref(false);
const mobileActionsProgress = ref(false);

const { isMobile } = store.platform;

const hasSlot = slotName => !!slots[slotName];
const toggleFilter = () => {
	store.updateFiltersShow(!store.state.filters.show);
	store.updateLocalGridConfig();
};

const clearFilters = () => emit('clear-filters');
const applyFilters = () => emit('apply-filters');

const startMobileProgress = () => {
	mobileActionsProgress.value = true;
};
const stopMobileProgress = () => {
	mobileActionsProgress.value = false;
};

const startHeaderMobileProgress = () => {
	mobileHeaderProgress.value = true;
};
const stopHeaderMobileProgress = () => {
	mobileHeaderProgress.value = false;
};
</script>

<style lang="scss" scoped>
.grid-header-area {
	display: flex;
	flex-direction: column;

	&.sticky-header {
		position: sticky;
		z-index: 2;
		top: 48px;
		background-color: #fff;
	}

	.grid-header {
		display: flex;
		align-items: center;
		gap: 8px;

		.help-icon-box {
			width: 50px;
			text-align: center;

			.help-icon {
				font-size: 24px;
				margin-left: 10px;
				margin-top: 12px;
			}
		}

		.sticky-btn {
			position: sticky;
			z-index: 1;
			left: 0;

			&.batch-actions-btn {
				left: 5px;
			}

			&.filters-btn {
				left: 5px;

				&.has-batch-actions {
					left: 203px;
				}
			}

			&.filter-apply-btn {
				left: 122px;

				&.has-batch-actions {
					left: 320px;
				}
			}

			&.filter-clear-btn {
				left: 235px;

				&.has-batch-actions {
					left: 433px;
				}
			}
		}

		.batch-actions-btn {
			background-color: #e8f0fe;
			color: #333;

			&:hover {
				:deep(.q-focus-helper) {
					opacity: 0.05;
				}
			}
		}

		.filter-toggle-btn {
			background-color: #f0f0f0;
			color: #333;

			&.active,
			&:hover {
				background-color: #e6f4ea;

				:deep(.q-focus-helper) {
					background-color: transparent;
				}
			}
		}
	}
}
</style>
