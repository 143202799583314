<template>
	<div class="q-mt-sm gap-md batch-action-tab-content">
		<q-btn
				unelevated no-caps outline
				color="white"
				text-color="black"
				class="q-btn--outline-gray"
				label="Update Brands from CRM"
				@click="showDialogUpdateBrands"
		/>

		<CrmDialog v-model="stateUpdateBrands">
			<template #title>
				Update Brands from CRM
			</template>
			<template #content>
				Are you sure want to update brands from CRM? It will take some time.
			</template>
			<template #actions>
				<q-btn unelevated no-caps
					   :loading="progressUpdateBrands"
					   label="Update"
					   color="positive"
					   @click="updateAllBrands"
				/>
				<q-btn v-close-popup
					   unelevated no-caps outline
					   label="Cancel"
					   class="q-btn--outline-gray"
				/>
			</template>
		</CrmDialog>

		<q-btn
				unelevated no-caps outline
				color="white"
				text-color="black"
				label="Update Options for All Brands"
				class="q-btn--outline-gray"
				@click="showDialogUpdateAllOption"
		/>
		<CrmDialog v-model="stateUpdateAllOption">
			<template #title>
				Update Options for All Brands
			</template>
			<template #content>
				Are you sure want to update options for all brands? It will take some time.
			</template>
			<template #actions>
				<q-btn unelevated no-caps
					   :loading="progressUpdateAllOption"
					   label="Update"
					   color="positive"
					   @click="updateAllOptions"
				/>
				<q-btn v-close-popup
					   unelevated no-caps outline
					   label="Cancel"
					   class="q-btn--outline-gray"
				/>
			</template>
		</CrmDialog>

		<q-btn
			unelevated no-caps outline
			color="white"
			text-color="black"
			class="q-btn--outline-gray"
			label="Update All product price"
			@click="showDialogUpdateAllProductPrice"
		/>

		<CrmDialog v-model="stateUpdateALlProductPrice">
			<template #title>
				Update All product prices
			</template>
			<template #content>
				Are you sure want to update all product prices? It will take some time.
			</template>
			<template #actions>
				<q-btn unelevated no-caps
					   :loading="progressUpdateAllProductPrice"
					   label="Update"
					   color="positive"
					   @click="updateAllProductPrice"
				/>
				<q-btn v-close-popup
					   unelevated no-caps outline
					   label="Cancel"
					   class="q-btn--outline-gray"
				/>
			</template>
		</CrmDialog>

		<q-btn
			unelevated no-caps outline
			color="white"
			text-color="black"
			class="q-btn--outline-gray"
			label="Import new products"
			:loading="progressImportNewProducts"
			@click="importProductsHandler"
		/>

		<CrmDialog v-model="stateImportNewProducts">
			<template #title>
				Task was started
			</template>
			<template #actions>
				<q-btn unelevated no-caps
						label="Ok"
						color="positive"
						@click="hideDialogImportNewProducts"
				/>
				<q-btn unelevated no-caps
						label="Go to tasks"
						color="info"
						@click="goToTask"
				/>
			</template>
		</CrmDialog>
	</div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import api from "@/api";
import CrmDialog from '@/components/dialog';

const { $showNotify } = inject('$app');

const router = useRouter();

const progressUpdateAllOption = ref(false);
const stateUpdateAllOption = ref(false);
const progressUpdateBrands = ref(false);
const progressImportNewProducts = ref(false);
const stateImportNewProducts = ref(false);
const stateUpdateBrands = ref(false);
const progressUpdateAllProductPrice = ref(false);
const stateUpdateALlProductPrice = ref(false);
const currentTaskId = ref(null);

const showDialogUpdateAllOption = () => {
	stateUpdateAllOption.value = true;
};
const hideDialogUpdateAllOption = () => {
	stateUpdateAllOption.value = false;
};
const updateAllOptions = async () => {
	const mode = 'CalculateAndPushCrmOptions';

	progressUpdateAllOption.value = true;

	try {
		await api.postStartProcessing({ mode });

		hideDialogUpdateAllOption();
		$showNotify({ type: 'positive', message: 'Upgrade options for all brands successfully started' });
	} finally {
		progressUpdateAllOption.value = false;
	}
};

const showDialogUpdateAllProductPrice = () => {
	stateUpdateALlProductPrice.value = true;
};
const hideDialogUpdateAllProductPrice = () => {
	stateUpdateALlProductPrice.value = false;
};
const updateAllProductPrice = async () => {
	const mode = 'PushProductsPrice';
	const is_crm_manufacturers = true;

	progressUpdateAllProductPrice.value = true;

	try {
		await api.postStartProcessing({ mode, is_crm_manufacturers });

		hideDialogUpdateAllProductPrice();
		$showNotify({ type: 'positive', message: 'Upgrade all product prices successfully started' });
	} finally {
		progressUpdateAllProductPrice.value = false;
	}
};

const showDialogUpdateBrands = () => {
	stateUpdateBrands.value = true;
};
const hideDialogUpdateBrands = () => {
	stateUpdateBrands.value = false;
};

const updateAllBrands = async () => {
	progressUpdateBrands.value = true;

	try {
		await api.fetchUpdateBrandsFromCrm();

		hideDialogUpdateBrands();

		$showNotify({ type: 'positive', message: 'Upgrade Brands from Crm successfully started' });
	} finally {
		progressUpdateBrands.value = false;
	}
};

const importProductsHandler = async () => {
	progressImportNewProducts.value = true;

	try {
		const taskId = await api.fetchImportNewProducts();

		showDialogImportNewProducts(taskId);
	} finally {
		progressImportNewProducts.value = false;
	}
};
const showDialogImportNewProducts = taskId => {
	stateImportNewProducts.value = true;
	currentTaskId.value = taskId;
};
const hideDialogImportNewProducts = () => {
	stateImportNewProducts.value = false;
	currentTaskId.value = null;
};
const goToTask = () => {
	const taskId = currentTaskId.value;

	hideDialogImportNewProducts();

	router.push({ name: 'Tasks', query: { taskId } });
};
</script>

<style lang="scss" scoped>
	.batch-action-tab-content {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		@media screen and (width >= 600px) {
			flex-direction: row;
			align-items: center;
		}
	}
</style>
