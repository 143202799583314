import { defineStore } from 'pinia';

export const useManufacturersStore = defineStore('manufacturers', {
	state: () => ({
		manufacturers: [],
	}),
	actions: {
		updateManufacturers(list) {
			this.manufacturers = list;
		}
	}
});
