import Notify from 'quasar/src/plugins/Notify.js';;

export const isBadRequestException = xhr => {
	const { body } = xhr;

	return (xhr && xhr.status === 400) && ((body && body.Type === 'BadRequestException') || (body.data && body.data.Type === 'BadRequestException'));
};

export const isRequestException = xhr => {
	const { body } = xhr;

	return (xhr && xhr.status === 500) && ((body && body.Type === 'Exception') || (body.data && body.data.Type === 'Exception'));
};

export const getRequestMessage = (xhr, truncate = false) => {
	try {
		if (xhr) {
			const statusText = xhr.message;
			const message = xhr.response?.data?.Message || xhr.response?.data?.data?.Message;

			return `${statusText}.${!truncate ? '<br>': ' '}${message}`;
		}
	} catch(e){
		console.warn('Could not get "Request" error message', e);
	}

	return null;
};

export const errorMessageHandler = options => {
	return error => {
		const message = options && options.message ? options.message : `Something went wrong: ${error.statusText || 'Error code ' + error.status}`;
		const xhrMessage = getRequestMessage(error);

		if (error.status !== 0 || error.status >= 100) {
			Notify.create({
				position: options && options.position ? options.position : 'top',
				timeout: options && options.timeout ? options.timeout : '2500',
				type: 'negative',
				actions: [
					{
						icon: 'mdi-close',
						color: 'white'
					}
				],
				message: message || xhrMessage,
				caption: xhrMessage
			});

			throw new Error(error);
		}
	};
};

export const showErrorMessage = (options, error) => errorMessageHandler(options)(error);
