<template>
	<div class="grid-footer-area">
		<slot name="footerBefore"></slot>

		<div :class="['grid-footer q-pa-sm', { 'mobile' : isMobile }]">
			<slot name="footerStart"></slot>

			<div v-if="!isMobile" class="rows-config">
				<slot v-if="hasSlot('footerRowsConfig')" name="footerRowsConfig" :store="store"></slot>
				<template v-else>
					Show rows:
					<q-select
							v-model="store.gridPagination.itemsPerPage"
							:options="store.gridPagination.itemsPerPageOptions"

							dense options-dense
							class="selector q-ml-sm"
							@update:model-value="triggerChangeShowRows"
					/>
				</template>
			</div>
			<template v-else>
				<q-select
						v-model="store.gridPagination.itemsPerPage"
						:options="store.gridPagination.itemsPerPageOptions"
						dense options-dense
						label="Show rows"
						@update:model-value="triggerChangeShowRows"
				/>
			</template>

			<template v-if="!isMobile">
				<div v-if="store.paginationPagesTotal" class="grid-pagination">
					<slot v-if="hasSlot('footerPagination')" name="footerPagination" :store="store"></slot>
					<template v-else>
						Pages:
						<q-pagination v-model="store.gridPagination.currentPage"
									  :max="store.paginationPagesTotal"
									  :boundary-links="false"
									  input direction-links
									  @update:model-value="triggerChangePagination"
						/>
					</template>
				</div>

				<slot v-if="hasSlot('footerItemsTotal')" name="footerItemsTotal" :store="store"></slot>
				<template v-else>
					<span v-if="store.gridItemsTotalLine" class="total">{{ store.gridItemsTotalLine }}</span>
				</template>
			</template>
			<template v-else>
				<div class="full-width flex justify-between items-center">
					<div v-if="store.paginationPagesTotal" class="grid-pagination">
						<q-pagination v-model="store.gridPagination.currentPage"
									  :max="store.paginationPagesTotal"
									  :boundary-links="false"
									  input direction-links
									  @update:model-value="triggerChangePagination"
						/>
					</div>

					<span v-if="store.gridItemsTotalLine" class="total">{{ store.gridItemsTotalLine }}</span>

					<q-btn
							v-if="store.gridItemsTotalLine"
							flat no-caps dense
							color="primary"
							icon="mdi-refresh"
							class="q-ml-sm"
							@click="triggerRefreshGridEvent"
					/>
				</div>
			</template>

			<slot name="footerPrepend"></slot>
			<q-space v-if="!isMobile" />
			<slot name="footerAppend"></slot>
			<div v-if="props.report" class="grid-info">
				<slot v-if="hasSlot('footerReport')" name="footerReport" :store="store" :report="props.report"></slot>
				<template v-else>
					<span :class="{ 'text-caption text-grey-8': isMobile }">Generated on {{ props.report.date }} at {{ props.report.time }}</span>
					<q-btn
							v-if="!isMobile"
							flat no-caps dense
							color="primary"
							icon="mdi-refresh"
							label="Refresh"
							class="q-ml-sm"
							@click="triggerRefreshGridEvent"
					/>
				</template>
			</div>
			<slot name="footerEnd"></slot>
		</div>

		<slot name="footerAfter"></slot>
	</div>
</template>

<script setup>
import { useSlots, inject } from 'vue';
import { useGridStore } from '../store';
import { STORE_KEY } from '../const';

const props = defineProps({
	report: {
		type: [ Object, null ],
		default: null
	}
});
const emit = defineEmits([
	'refresh-grid',
	'show-rows',
	'pagination'
]);

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const slots = useSlots();

const { isMobile } = store.platform;

const hasSlot = slotName => !!slots[slotName];

const triggerRefreshGridEvent = () => emit('refresh-grid');
const triggerChangeShowRows = () => emit('show-rows');
const triggerChangePagination = () => emit('pagination');
</script>

<style lang="scss" scoped>
.grid-footer-area {
	display: flex;
	flex-direction: column;
	margin-top: 10px;

	.grid-footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 20px;
		background-color: #f2f2f2;
		border: 1px solid #e9e9e9;
		margin-bottom: 0;
		border-radius: 4px;

		&.mobile {
			flex-direction: column;
			align-items: flex-start;
			padding: 10px;
			gap: 5px;

			.q-field {
				width: 100%;
			}

			.grid-info {
				width: 100%;
				justify-content: center;
			}
		}

		.rows-config {
			display: flex;
			align-items: center;
			gap: 5px;

			.selector {
				width: 50px;
			}
		}

		.grid-info,
		.grid-pagination {
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}
}
</style>
