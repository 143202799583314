import notificationLogo from './assets/4ib-push-logo-128.png';
import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';;

class IframeSync {
	iframeRef = null;
	iframeUrl = null;

	#origin = null;
	#metaStore = null;

	constructor({ externalAppRef, origin, metaStore }) {
		if (!origin || !externalAppRef) {
			console.error('IframeSync Not initialize: externalAppRef and origin are required.');

			return;
		}

		this.iframeRef = externalAppRef.value;
		this.#origin = origin;
		this.#metaStore = metaStore;

		this.#bindEvent();
	}
	#bindEvent() {
		window.addEventListener('message', this.#onIframeMessage.bind(this));
		window.addEventListener('hashchange', this.#compareUrls.bind(this));
	}

	#compareUrls() {
		const path = window.location.href.split('#').pop();

		if (path !== this.iframeUrl) {
			if (!this.iframeRef || !this.iframeRef.contentWindow) {
				return;
			}

			this.iframeRef.contentWindow.postMessage({ type: 'url', path }, this.#origin);
		}
	}
	#onClientReady() {
		this.#changeIframeContext();
		this.#applyInitialIframeUrl();
	}
	#changeIframeContext() {
		if (!this.iframeRef || !this.iframeRef.contentWindow) {
			return;
		}

		const canAccessIframe = Boolean(this.iframeRef.contentDocument);

		if (canAccessIframe) {
			this.iframeRef.contentWindow.history.replaceState({}, '', window.location.pathname);
		}
	}
	#applyInitialIframeUrl() {
		if (!this.iframeUrl && this.iframeRef && this.iframeRef.contentWindow) {
			const { href } = window.location;
			const path = href.includes('#') ? href.split('#').pop() : null;

			this.iframeUrl = path;

			this.iframeRef.contentWindow.postMessage({ type: 'url', path }, this.#origin);
		}
	}
	#updateUrl({ path }) {
		const { pathname } = window.location;

		if (this.iframeUrl !== path) {
			this.iframeUrl = path;

			window.history.replaceState({}, '', `${pathname}#${path}`);
		}
	}
	#sendNotification(message) {
		const { newMessage } = message;

		window.Notification.requestPermission().then(permission => {
			if (permission === 'granted') {
				const ownerName = `${newMessage.Owner.FirstName} ${newMessage.Owner.LastName}`;
				const text = newMessage.Message.replace(/(&nbsp;|<([^>]+)>)/ig, ' ').replace(/ +/g, ' ').trim();
				const notification = new Notification(ownerName, {
					icon: notificationLogo,
					body: text
				});

				notification.addEventListener('click', () => window.open(window.location.href), { once: true });
			}
		});
	}
	#showError(error) {
		const message = error && error.message ? error.message : 'Something went wrong: ';
		const caption = error && error.caption ? error.caption : 'Error.';

		Notify.create({
			position: error && error.position ? error.position : 'top',
			timeout: error && error.timeout ? error.timeout : '2500',
			type: 'negative',
			actions: [ { icon: 'mdi-close', color: 'white' } ],
			message: message,
			caption: caption
		});
	}
	#showLoading({ state }) {
		const action = state ? 'start' : 'stop';

		LoadingBar[action]();
	}
	#applyStyles({ styles }) {
		for (let key in styles) {
			this.iframeRef.setAttribute(key, styles[key]);
		}
	}
	#applyMeta({ meta }) {
		if (!meta) {
			return;
		}

		if (meta.title !== this.#metaStore.title) {
			this.#metaStore.updateTitle(meta.title);
		}
	}
	#onIframeMessage({ data, origin }) {
		if (!data || !this.#origin.includes(origin)) {
			return;
		}

		const actions = {
			ready: () => this.#onClientReady(),
			url: () => this.#updateUrl(data),
			notification: () => this.#sendNotification(data),
			error: () => this.#showError(data),
			loading: () => this.#showLoading(data),
			style: () => this.#applyStyles(data),
			meta: () => this.#applyMeta(data)
		};
		const action = actions[data.type];

		action && action();
	}
}

class IframeSyncClient {
	#router = null;
	#origin = null;
	#referrer = document.referrer;

	constructor({ router, origin }) {
		this.#router = router;
		this.#origin = origin;

		this.#injectRouterPostEvents();
		this.#bindEvents();
		this.#checkLoaded();
	}

	get origin() {
		return this.#origin;
	}

	sendMessage(message) {
		if (!window.parent && !window.parent.postMessage) {
			return;
		}

		window.parent.postMessage(message, this.origin);
	}

	#injectRouterPostEvents() {
		if (!this.#router || (!window.parent && !window.parent.postMessage)) {
			return;
		}

		this.#router.beforeEach((to, from, next) => {
			const { path, meta } = to;

			window.parent.postMessage({ type: 'url', path }, this.#origin);
			meta && window.parent.postMessage({ type: 'meta', meta }, this.#origin);

			next();
		});
	}

	#bindEvents() {
		window.addEventListener('readystatechange', this.#checkLoaded.bind(this));
		window.addEventListener('message', ({ data, origin }) => {
			if (!this.#referrer.includes(origin)) {
				return;
			}

			const { type, path } = data;
			const actions = {
				url: () => this.#updateUrl(path)
			};
			const action = actions[type];

			action && action();
		});
	}

	async #updateUrl(path) {
		if (!this.#router || !path) {
			return;
		}

		await this.#router.push(path);

		// DEVELOPER: NO NOT REMOVE YET
		// 	if (!window.frameElement) {
		// 		return;
		// 	}
		//
		// 	window.frameElement.setAttribute('style', `height: ${(() => 'calc(100% - 120px)')()}; position: absolute; top: 60px; left: 0;`);
		// 	window.frameElement.setAttribute('scrolling', 'auto');
	}

	#checkLoaded() {
		if ('loading' === document.readyState || !window.parent) {
			return;
		}

		window.parent.postMessage({ type: 'ready' }, this.#origin);
	}
}

export const useIframeSync = config => new IframeSync(config);
export const useIframeSyncClient = config => new IframeSyncClient(config);

export default { useIframeSync, useIframeSyncClient };
