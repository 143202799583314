import { createRouter, createWebHistory } from 'vue-router';
import Main from '../layouts/Main.vue'
import Details from '../layouts/Details';

import LogGrid from "../views/tabs/log/List";
import Settings from "../views/tabs/settings";
import ProductsGrid from "../views/tabs/products/List";
import ManufacturersGrid from "../views/tabs/manufacturers/List";
import BatchAction from "../views/tabs/batch-action";
import TasksGrid from "../views/tabs/tasks/List";
import TaskErrorsList from "../views/tabs/tasks/Errors";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Main,
	redirect: { name: 'Log' }, // DEVELOPER, BE CAREFUL! The route names must be unicue in all over SPA!
	children: [
		{
			path: 'log',
			name: 'Log',
			component: LogGrid,
			meta: { title: 'Log' }
		},
		{
			path: 'settings',
			name: 'Settings',
			component: Settings,
			meta: { title: 'Settings' }
		},
		{
			path: 'products',
			name: 'Products',
			component: ProductsGrid,
			meta: { title: 'Products' }
		},
		{
			path: 'Manufacturers',
			name: 'manufacturers',
			component: ManufacturersGrid,
			meta: { title: 'Manufacturers' }
		},
		{
			path: 'batch-action',
			name: 'Batch Action',
			component: BatchAction,
			meta: { title: 'Batch Action' }
		},
		{
			path: 'tasks',
			name: 'Tasks',
			component: TasksGrid,
			meta: { title: 'Tasks' }
		}
	]
  },
	{
		path: '/task/:taskId',
		component: Details,
		name: 'task-details',
		props: route => ({ taskId: route.params.taskId }),
		redirect: { name: 'task-errors' }
		,
		children: [
			{
				path: '',
				name: 'task-errors',
				props: route => ({ taskId: route.params.taskId }),
				component: TaskErrorsList,
			},
		]
	}
]

const scrollBehavior = () => ({ left: 0, top: 0 });
const history = createWebHistory();

export default createRouter({ routes, scrollBehavior, history });
