<template>
	<q-select :model-value="props.filter[modelKey]"
			  :options="options"
			  :placeholder="props.rangeTitle || props.filter.placeholder || props.column.title"
			  :style="props.filter.style"
			  :rules="props.filter.rules"
			  class="single-line-value-select"
			  bg-color="white"
			  clear-icon="mdi-close"
			  outlined multiple
			  dense options-dense
			  clearable hide-bottom-space
			  @add="add"
			  @remove="remove"
			  @clear="clear"
	>
		<template #before-options>
			<q-item v-ripple dense tag="label" class="bg-grey-3 cursor-pointer">
				<q-item-section avatar>
					<q-checkbox :model-value="selectAll" dense
								indeterminate-value="partial"
								@update:model-value="toggleAllSelected"
					/>
				</q-item-section>
				<q-item-section>
					<q-item-label>Select all</q-item-label>
				</q-item-section>
			</q-item>
		</template>
		<template #option="{ itemProps, opt, selected, toggleOption }">
			<q-item v-ripple v-bind="itemProps">
				<q-item-section avatar>
					<q-checkbox :model-value="selected"
								dense
								@update:model-value="toggleOption(opt)"
					/>
				</q-item-section>
				<q-item-section>
					<q-item-label>{{ opt.label }}</q-item-label>
				</q-item-section>
			</q-item>
		</template>
	</q-select>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
	modelKey: {
		type: String,
		required: true
	},
	filter: {
		type: Object,
		required: true
	},
	column: {
		type: Object,
		required: true
	},
	rangeTitle: {
		type: [ String, null ],
		default: null
	}
});

const filterOptions = props.filter.options.map(option => ({
	label: option[props.filter.optionLabel],
	value: option[props.filter.optionValue]
}));
const options = ref(filterOptions);
const selectAll = ref(false);

const setSelectAllPartial = () => {
	const value = props.filter[props.modelKey];

	selectAll.value = value && !!value.length ? value.length !== options.value.length ? 'partial' : true : false;
};
const add = ({ value }) => {
	props.filter[props.modelKey] = props.filter[props.modelKey] ? [ ...props.filter[props.modelKey], value ] : [ value ];

	setSelectAllPartial();
};
const remove = ({ value }) => {
	props.filter[props.modelKey] = props.filter[props.modelKey].filter(option => option.value !== value.value);

	setSelectAllPartial();
};
const clear = () => {
	props.filter[props.modelKey] = null;

	setSelectAllPartial();
};
const toggleAllSelected = () => {
	selectAll.value = selectAll.value !== 'partial' ? !selectAll.value : false;
	props.filter[props.modelKey] = selectAll.value !== 'partial' ? selectAll.value ? [ ...options.value ] : null : null;
};
</script>
