<template>
	<div class="log-tab-content">
		<Grid v-if="!!gridConfig" ref="grid" v-bind="gridConfig">
			<template #cellActions="{ item }">
				<q-btn label="" icon="mdi-dots-vertical" unelevated round>
					<q-menu>
						<q-list>
							<q-item clickable v-close-popup @click="downloadDiff(item.id)">
								<q-item-section>
									Difference
								</q-item-section>
								<q-item-section class="q-pr-none items-center" avatar>
									<q-icon name="mdi-download" />
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup @click="downloadRequest(item.id)">
								<q-item-section>
									Crm Request
								</q-item-section>
								<q-item-section class="q-pr-none items-center" avatar>
									<q-icon name="mdi-download" />
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup @click="downloadResponse(item.id)">
								<q-item-section>
									Crm Response
								</q-item-section>
								<q-item-section class="q-pr-none items-center" avatar>
									<q-icon name="mdi-download" />
								</q-item-section>
							</q-item>
						</q-list>
					</q-menu>
				</q-btn>
			</template>
			<template #cellMobileActions="{ item }">
				<div class="q-gutter-sm q-pb-sm">
					<q-btn
							unelevated no-caps outline dense
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							label="Difference"
							@click="downloadDiff(item.id)"
					/>
					<q-btn
							unelevated no-caps outline dense
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							label="Request"
							@click="downloadRequest(item.id)"
					/>
					<q-btn
							unelevated no-caps outline dense
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							label="Response"
							@click="downloadResponse(item.id)"
					/>
				</div>
			</template>
		</Grid>
	</div>
</template>

<script>
import { ref } from 'vue';
import { Platform } from 'quasar';
import Grid, { formatters } from '@components/grid';
import api, {urls} from '@/api';
import {API_HOST} from "@/global/plugins/axios";
import { useLookupsStore } from '@/store';

export default {
	components: {
		Grid
	},
	beforeRouteEnter(to, from, next) {
		const { serviceId } = from.params;

		if (!serviceId) {
			next();
		} else {
			next(vm => vm.highlightGridItem(serviceId));
		}
	},
	setup(props, { expose }) {
		const lookupsStore = useLookupsStore();
		const grid = ref(null);
		const gridConfig = ref(null);
		const LogLevelLookup = [
			{label: 'None', value: 'None', class: ''},
			{label: 'Trace', value: 'Trace', class: 'grid-item-log-level-trace'},
			{label: 'Debug', value: 'Debug', class: 'grid-item-log-level-debug'},
			{label: 'Information', value: 'Information', class: ''},
			{label: 'Warning', value: 'Warning', class: 'grid-item-log-level-warning'},
			{label: 'Error', value: 'Error', class: 'grid-item-log-level-error'},
			{label: 'Critical', value: 'Critical', class: 'grid-item-log-level-critical'}
		];

		const initGridConfig = () => {
			const url = api.fetchLogGrid();

			gridConfig.value = {
				api: { url, method: 'POST' },
				itemIdKey: 'id',
				gridId: 'LogGrid',
				remoteFieldsConfig: true,
				itemSelectionEnable: false,
				pagination: {
					itemsPerPageOptions: [10, 50, 100, 500]
				},
				columns: [
					{
						field: 'createdAt',
						title: 'Date',
						format: value => formatters.date(value),
						filter: {
							style: 'min-width: 135px;',
						}
					},
					{
						title: 'Action',
						field: 'action'
					},
					{
						title: 'Brand',
						field: 'manufacturerName'
					},
					{
						title: 'Sku',
						field: 'model'
					},
					{
						title: 'Code',
						field: 'statusCode',
						filter: {
							style: 'min-width: 100px;',
						}
					},
					{
						title: 'Status',
						field: 'status'
					},
					{
						title: 'Level',
						field: 'logLevel',
						filter: {
							type: 'select',
							options: lookupsStore.LogLevelLookup
						}
					},
					{
						title: Platform.is.mobile ? 'Download' : '',
						name: 'Actions',
						sortable: false,
						filterable: false
					},
				]
			};
		};


		const highlightGridItem = serviceId => {
			grid.value.highlightItems([serviceId]);
		};

		const itemClass = item => {
			if (!item) {
				return '';
			}

			if (item.logLevel) {
				const lookup = LogLevelLookup.find(lookup => lookup.value === item.logLevel);

				return lookup ? lookup.class : '';
			} else {
				return (item.statusCode === 200 || item.statusCode === 0)
						? 'grid-item'
						: 'grid-item-error-code';
			}
		};

		const downloadRequest = (id) => {
			getFile(`${API_HOST}${urls.log.request(id)}`);
		};
		const downloadResponse = (id) => {
			getFile(`${API_HOST}${urls.log.response(id)}`);
		};
		const downloadDiff = (id) => {
			getFile(`${API_HOST}${urls.log.diff(id)}`);
		};
		const getFile = (url) => {
			if (!url) {
				return;
			}

			const a = document.createElement('a');

			a.href = url;
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			setTimeout(function () {
				URL.revokeObjectURL(a.href);
			}, 1500);
		};

		expose({
			highlightGridItem
		});

		initGridConfig();

		return {
			grid,
			gridConfig,
			downloadRequest,
			downloadResponse,
			downloadDiff,
			itemClass
		};
	}
};
</script>

<style lang="scss" scoped>
@keyframes blinker {
	50% {
		background-color: rgba(255, 235, 160, .75);
	}
}

tbody {
	tr {
		td {
			vertical-align: initial;
			padding: 6px;

			&.sorted {
				background-color: #f6f6f6;
			}

			&.selection-column-body {
				width: 50px;
				text-align: center;
			}

			&.sticky-column {
				position: sticky;
				z-index: 1;
				left: 0;
				background-color: #f2f2f2;
				border-right: 1px solid #ddd;

				&.has-selector {
					left: 33px;
				}
			}
		}

		&.blink {
			animation: blinker .3s linear infinite;
		}

		&.selected {
			background-color: #eff5ff;
		}

		&:hover {
			background-color: #fafafa;
		}

		&.highlighted {
			background-color: #fffae5;
		}

		&:focus-visible {
			outline: none;
		}
	}

	&:first-of-type {
		tr {
			td {
				padding: 8px 6px 6px;
			}
		}
	}
}
</style>

<style lang="scss">
tbody {
	.grid-item-error-code {
		background: #ffdfd4;
	}

	.grid-item-log-level-trace {
		background: #bdbebd;
	}

	.grid-item-log-level-debug {
		background: #949494;
	}

	.grid-item-log-level-warning {
		background: #ffff00;
	}

	.grid-item-log-level-error {
		background: #e34234;
	}

	.grid-item-log-level-critical {
		background: #ff033e;
	}
}
</style>
