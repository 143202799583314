<template>
	<div class="grid-responsive">
		<table :class="`grid-table ${props.separator}`">
			<thead :class="{'sticky-header': store.isStickyHeader}">
			<TableColumnGroups v-if="store.gridColumnGroups" />
			<TableColumns @all-selected="triggerAllSelection" @sort="triggerSort">
				<template v-for="name in Object.keys(slots)" #[name]="slotData">
					<slot :name="name" v-bind="slotData"></slot>
				</template>
			</TableColumns>
			<TableFilters v-if="store.state.filters.show" @apply-filters="triggersApplyFiltersEvent">
				<template v-for="name in Object.keys(slots)" #[name]="slotData">
					<slot :name="name" v-bind="slotData"></slot>
				</template>
			</TableFilters>
			<TableSummary v-if="store.hasGridSummaryFields">
				<template v-for="name in Object.keys(slots)" #[name]="slotData">
					<slot :name="name" v-bind="slotData"></slot>
				</template>
			</TableSummary>
			<TableProgress />
			</thead>

			<TableItemsError v-if="store.state.gridError" />
			<TableItems v-else-if="store.hasGridItems" @select="triggerSelect">
				<template v-for="name in Object.keys(slots)" #[name]="slotData">
					<slot :name="name" v-bind="slotData"></slot>
				</template>
			</TableItems>
			<TableItemsEmpty v-else-if="store.emptyGridItems" />
			<TableSkeleton v-else-if="store.state.firstGridRequest" />
		</table>
	</div>
</template>

<script setup>
import { useSlots, inject } from 'vue';
import { useGridStore } from '../store';
import { STORE_KEY } from '../const';

import TableSkeleton from './table/Skeleton';
import TableColumnGroups from './table/ColumnGroups';
import TableFilters from './table/Filters';
import TableProgress from './table/Progress';
import TableSummary from './table/Summary';
import TableColumns from './table/Columns';
import TableItems from './table/Items';
import TableItemsEmpty from './table/ItemsEmpty';
import TableItemsError from './table/ItemsError';

const props = defineProps({
	separator: {
		type: String,
		required: true
	}
});
const emit = defineEmits([
	'all-selected',
	'sort',
	'select',
	'apply-filters'
]);
const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const slots = useSlots();

const triggerAllSelection = event => emit('all-selected', event);
const triggerSort = column => emit('sort', column);
const triggerSelect = item => emit('select', item);
const triggersApplyFiltersEvent = () => emit('apply-filters');
</script>

<style lang="scss" scoped>
.grid-responsive {
	flex-grow: 1;

	.grid-table {
		width: 100%;
		max-width: 100%;
		border: 1px solid #ddd;
		border-top: none;
		margin-bottom: 0;
		background-color: transparent;
		border-spacing: initial;
		border-collapse: initial;

		thead {
			&.sticky-header {
				position: sticky;
				z-index: 2;
				top: 100px;
				background-color: #fff;
			}
		}

		tbody {
			tr {
				td {
					vertical-align: initial;
					padding: 6px;

					&.sorted {
						background-color: #f6f6f6;
					}

					&.selection-column-body {
						width: 50px;
						text-align: center;
					}
				}

				&.blink {
					animation: blinker .3s linear infinite;
				}

				&.selected {
					background-color: #eff5ff;
				}

				&:hover {
					background-color: #fafafa;
				}
			}

			&:first-of-type {
				tr {
					td {
						padding: 8px 6px 6px;
					}
				}
			}
		}

		&.horizontal {
			tbody {
				&:not(:last-child) {
					tr {
						td {
							border-bottom: 1px solid #ddd;
						}
					}
				}
			}
		}

		&.vertical {
			tbody {
				tr {
					td {
						&:not(:last-child) {
							border-right: 1px solid #ddd;
						}
					}
				}
			}
		}

		&.cell {
			tbody {
				tr {
					td {
						&:not(:last-child) {
							border-right: 1px solid #ddd;
						}
					}
				}

				&:not(:last-child) {
					tr {
						td {
							border-bottom: 1px solid #ddd;
						}
					}
				}
			}
		}
	}
}
</style>
