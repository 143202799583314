<template>
	<div v-if="props.column.filterable" :data-filter="props.column.name">
		<div v-if="isFilterTypeInput" class="flex column q-gutter-sm">
			<TextControl v-if="!filter.range"
						 v-model="filter.model"
						 :type="inputType"
						 :filter="filter"
						 :column="props.column"
						 @keydown="applyFiltersHandler"
						 @clear="clearFiltersHandler"
			/>
			<template v-else>
				<TextControl v-model="filter.modelRangeFrom"
							 :type="inputType"
							 :filter="filter"
							 :column="props.column"
							 range-title="From"
							 @keydown="applyFiltersHandler"
							 @clear="clearFiltersHandler"
				/>

				<TextControl v-model="filter.modelRangeTo"
							 :type="inputType"
							 :filter="filter"
							 :column="props.column"
							 range-title="To"
							 @keydown="applyFiltersHandler"
							 @clear="clearFiltersHandler"
				/>
			</template>
		</div>
		<div v-if="isFilterTypeDate" class="flex column q-gutter-sm">
			<DateControl v-if="!filter.range"
						 v-model="filter.model"
						 :filter="filter"
						 :column="props.column"
						 @keydown="applyFiltersHandler"
			>
				<template #datepicker>
					<q-popup-proxy :ref="`filterDateControlProxy${props.column.name}`"
								   cover transition-show="scale"
								   transition-hide="scale"
					>
						<q-date v-model="filter.model"
								:mask="filter.dateFormat" minimal
								@update:model-value="() => $refs[`filterDateControlProxy${props.column.name}`].hide()"
						/>
					</q-popup-proxy>
				</template>
			</DateControl>
			<template v-else>
				<DateControl v-model="filter.modelRangeFrom"
							 :filter="filter"
							 :column="props.column"
							 range-title="From"
							 @keydown="applyFiltersHandler"
				>
					<template #datepicker>
						<q-popup-proxy :ref="`filterDateControlFromProxy${props.column.name}`"
									   cover transition-show="scale"
									   transition-hide="scale"
						>
							<q-date v-model="filter.modelRangeFrom"
									:mask="filter.dateFormat" minimal
									@update:model-value="() => $refs[`filterDateControlFromProxy${props.column.name}`].hide()"
							/>
						</q-popup-proxy>
					</template>
				</DateControl>

				<DateControl v-model="filter.modelRangeTo"
							 :filter="filter"
							 :column="props.column"
							 range-title="To"
							 @keydown="applyFiltersHandler"
				>
					<template #datepicker>
						<q-popup-proxy :ref="`filterDateControlToProxy${props.column.name}`"
									   cover transition-show="scale"
									   transition-hide="scale"
						>
							<q-date v-model="filter.modelRangeTo"
									:mask="filter.dateFormat" minimal
									@update:model-value="() => $refs[`filterDateControlToProxy${props.column.name}`].hide()"
							/>
						</q-popup-proxy>
					</template>
				</DateControl>
			</template>
		</div>
		<div v-if="isFilterTypeSelect" class="flex column q-gutter-sm">
			<SelectControl v-if="!filter.range"
						   :filter="filter"
						   :column="props.column"
						   model-key="model"
			>
				<template v-if="filter.model" #clear>
					<q-icon name="mdi-close" class="cursor-pointer" @click.stop.prevent="filter.model = null" />
				</template>
				<template #no-option>
					<q-item>
						<q-item-section class="text-grey">
							No results
						</q-item-section>
					</q-item>
				</template>
			</SelectControl>
			<template v-else>
				<SelectControl :filter="filter"
							   :column="props.column"
							   range-title="From"
							   model-key="modelRangeFrom"
				/>

				<SelectControl :filter="filter"
							   :column="props.column"
							   range-title="To"
							   model-key="modelRangeTo"
				/>
			</template>
		</div>
		<div v-if="isFilterTypeTextSelect" class="flex column q-gutter-sm">
			<SelectControl v-if="!filter.range"
						   :filter="filter"
						   :column="props.column"
						   model-key="model"
						   @keydown="applyFiltersHandler"
			>
				<template v-if="filter.model" #clear>
					<q-icon name="mdi-close" class="cursor-pointer" @click.stop.prevent="filter.model = null" />
				</template>
			</SelectControl>
			<template v-else>
				<SelectControl :filter="filter"
							   :column="props.column"
							   range-title="From"
							   model-key="modelRangeFrom"
							   @keydown="applyFiltersHandler"
				/>

				<SelectControl :filter="filter"
							   :column="props.column"
							   range-title="To"
							   model-key="modelRangeTo"
							   @keydown="applyFiltersHandler"
				/>
			</template>
		</div>
		<div v-if="isFilterTypeLookup" class="flex column q-gutter-sm">
			<LookupControl v-if="!filter.range"
						   :filter="filter"
						   :column="props.column"
						   model-key="model"
			/>
			<template v-else>
				<LookupControl :filter="filter"
							   :column="props.column"
							   range-title="From"
							   model-key="modelRangeFrom"
				/>

				<LookupControl :filter="filter"
							   :column="props.column"
							   range-title="To"
							   model-key="modelRangeTo"
				/>
			</template>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';

import TextControl from './filter/TextControl';
import DateControl from './filter/DateControl';
import SelectControl from './filter/SelectControl';
import LookupControl from './filter/LookupControl';

const props = defineProps({
	column: {
		type: Object,
		required: true
	}
});

const emit = defineEmits([
	'apply-filters',
	'clear-filters'
]);

const filter = computed(() => props.column.filter);

const isFilterTypeInput = filter.value.type === 'input';
const isFilterTypeSelect = filter.value.type === 'select';
const isFilterTypeTextSelect = filter.value.type === 'textSelect';
const isFilterTypeDate = filter.value.type === 'date';
const isFilterTypeLookup = filter.value.type === 'lookup';

const getInputDataType = filterDataType => {
	const types = {
		Guid: 'text',
		String: 'text',
		Boolean: 'text',
		DateTime: 'date',
		Date: 'date',
		Time: 'date',
		Number: 'number',
		Decimal: 'number',
		Float: 'number',
		Int32: 'number',
		Int64: 'number'
	};

	return types[filterDataType] || 'text';
};

const inputType = ref(getInputDataType(filter.value.data));

const clearFiltersHandler = () => {
	emit('clear-filters');
};
const applyFiltersHandler = key => {
	const applyCodes = [ 'Enter', 'NumpadEnter' ];
	const applyFiltersKeyPressed = applyCodes.includes(key.code);

	if (applyFiltersKeyPressed) {
		key.preventDefault();

		emit('apply-filters');
	}
};
</script>
