import EventBus from 'quasar/src/utils/EventBus.js';import Notify from 'quasar/src/plugins/Notify.js';;
import CONSTANTS from '@const';

export const AppHeaderEventBus = new EventBus();

// app header
const $setAppHeader = config => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.SET, config);
};
const $resetAppHeader = () => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.RESET);
};
const $setAppHeaderTitle = title => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.SET_TITLE, title);
};
const $resetAppHeaderTitle = () => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.RESET_TITLE);
};
const $setAppHeaderBacklink = backLink => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.SET_BACK_LINK, backLink);
};
const $resetAppHeaderBacklink = () => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.RESET_BACK_LINK);
};
const $setAppHeaderAppIcon = appIcon => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.SET_APP_ICON, appIcon);
};
const $resetAppHeaderAppIcon = () => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.RESET_APP_ICON);
};
const $setAppHeaderOptions = options => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.SET_OPTIONS, options);
};
const $resetAppHeaderOptions = () => {
	AppHeaderEventBus.emit(CONSTANTS.EVENTS.APP_HEADER.RESET_OPTIONS);
};

// notify
const $showNotify = ({ position, timeout, type, message, caption }) => {
	Notify.create({
		message,
		caption,
		position: position || 'top',
		timeout: timeout || '2500',
		type: type || 'positive',
		actions: [{ icon: 'mdi-close', color: 'white' }]
	});
};

export default {
	install: app => {
		
		app.config.globalProperties.$app = {
			// notify
			$showNotify,
			
			// app header
			$setAppHeader,
			$resetAppHeader,
			$setAppHeaderTitle,
			$resetAppHeaderTitle,
			$setAppHeaderBacklink,
			$resetAppHeaderBacklink,
			$setAppHeaderAppIcon,
			$resetAppHeaderAppIcon,
			$setAppHeaderOptions,
			$resetAppHeaderOptions
		};
		
		app.mixin({
			data: () => ({
				// app header
				$appHeader: {
					backLink: null,
					appIcon: null,
					title: null,
					options: null
				}
			}),
			created() {
				// app header
				this.$bindAppHeaderEvents();
			},
			methods: {
				// notify
				$showNotify,
				
				// app header
				$bindAppHeaderEvents() {
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.SET, ({ title, backLink, appIcon, options }) => {
						if (title) {
							this.$data.$appHeader.title = title;
						}
						
						if (backLink && this.$data.$appHeader.backLink !== backLink) {
							this.$data.$appHeader.backLink = backLink;
						}
						
						if (appIcon && this.$data.$appHeader.appIcon !== appIcon) {
							this.$data.$appHeader.appIcon = appIcon;
						}
						
						if (options) {
							this.$data.$appHeader.options = options;
						}
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.RESET, () => {
						this.$data.$appHeader.title = null;
						this.$data.$appHeader.backLink = null;
						this.$data.$appHeader.appIcon = null;
						this.$data.$appHeader.options = null;
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.SET_TITLE, title => {
						this.$data.$appHeader.title = title;
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.RESET_TITLE, () => {
						this.$data.$appHeader.title = null;
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.SET_BACK_LINK, backLink => {
						if (this.$data.$appHeader.backLink !== backLink) {
							this.$data.$appHeader.backLink = backLink;
						}
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.RESET_BACK_LINK, () => {
						this.$data.$appHeader.backLink = null;
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.SET_APP_ICON, appIcon => {
						if (this.$data.$appHeader.appIcon !== appIcon) {
							this.$data.$appHeader.appIcon = appIcon;
						}
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.RESET_APP_ICON, () => {
						this.$data.$appHeader.appIcon = null;
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.SET_OPTIONS, options => {
						this.$data.$appHeader.appIcon = options;
					});
					AppHeaderEventBus.on(CONSTANTS.EVENTS.APP_HEADER.RESET_OPTIONS, () => {
						this.$data.$appHeader.options = null;
					});
					
				},
				$setAppHeader,
				$resetAppHeader,
				$setAppHeaderTitle,
				$resetAppHeaderTitle,
				$setAppHeaderBacklink,
				$resetAppHeaderBacklink,
				$setAppHeaderAppIcon,
				$resetAppHeaderAppIcon,
				$setAppHeaderOptions,
				$resetAppHeaderOptions
			}
		});
		
		app.provide('$app', app.config.globalProperties.$app);
	}
};
