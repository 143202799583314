<template>
	<q-card
		flat bordered
		class="my-card"
	>
		<q-scroll-area style="height: 300px; max-height:  calc(100vh - 325px)">
			<q-card-section v-if="props.subtitle">
				<div class="text-grey-6">{{ props.subtitle }}</div>
			</q-card-section>
			<q-inner-loading v-if="props.loading" showing>
				<q-spinner color="primary" size="3em" />
			</q-inner-loading>
			<q-card-section v-else-if="props.list && props.list.length" class="q-pt-none">
				<q-list v-if="existSubItems" class="rounded-borders">
					<q-expansion-item
							v-for="list_item in props.list"
							:key="list_item.description"
							:value="list_item.description"
							expand-separator
							:content-inset-level="0.2"
							header-class="q-pa-none"
					>
						<template v-slot:header>
							<q-checkbox v-model="list_item.select"/>
							<q-item-section>
								<q-item-label>{{ list_item.description }}</q-item-label>
							</q-item-section>
						</template>
						<q-list class="rounded-borders">
							<q-item
									v-for="value_item in list_item.values"
									:key="value_item.value"
									:value="value_item.value"
									class="q-pa-none"
							>
								<q-checkbox v-model="value_item.select"/>
								<q-item-section>
									<q-item-label class="list-item" >{{ value_item.description }}</q-item-label>
								</q-item-section>
							</q-item>
						</q-list>
					</q-expansion-item>
				</q-list>
				<q-list v-else class="rounded-borders">
					<q-item
							v-for="(list_item, idx) in props.list"
							:key="`black-list-${idx}`"
							class="q-pa-none"
					>
						<q-item-section>
							<q-input
									v-model="list_item.description"
									required dense
							/>
						</q-item-section>
						<q-item-section class="list-item-controls" avatar>
							<q-icon v-show="isEdited(list_item)" color="positive" name="mdi-check" class="cursor-pointer" @click="update"/>
							<q-icon color="grey-6" :name="isEdited(list_item) ? 'mdi-close' : 'mdi-trash-can-outline'" class="cursor-pointer" @click="remove(list_item, idx)"/>
						</q-item-section>
					</q-item>
				</q-list>
			</q-card-section>
			<q-card-section v-else class="q-pt-none">
				<q-banner class="bg-white">
					Empty list
				</q-banner>
			</q-card-section>
		</q-scroll-area>
	</q-card>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits([ 'remove', 'update', 'cancelEdit' ]);

const props = defineProps({
	list: {
		type: Array,
		required: true,
	},
	subtitle: {
		type: String
	},
	loading: {
		type: Boolean,
		default: false
	}
})

const existSubItems = computed(() => props.list && props.list.length && props.list.some(item => item.values && item.values.length) );
const isEdited = (item) => item.description !== item.oldDescription ;

const remove = (item, idx) => {
	if (isEdited(item)) {
		item.description = item.oldDescription;
	} else {
		emit('remove', idx);
	}
}
const update = () => emit('update');
</script>
<style lang="scss" scoped>
.list-item {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.list-item-controls {
	flex-direction: row;
	align-items: center;
	min-width: auto;
}
</style>
