import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Platform from 'quasar/src/plugins/Platform.js';;
import { defineStore } from 'pinia';
import formatGridColumnFilterValue from '../utils/formatters/column-filter';

const defaultColumnGroupSettings = {
	title: null,
	align: 'start',
	length: 1,
	style: null,
	classes: null
};
const defaultColumnSettings = {
	name: '', //unique string
	title: null, // string
	field: null, // string
	titleAlign: 'start', // string // start left center right end
	align: 'start', // string // start left center right end
	style: null, // string
	classes: null, // string
	format: value => value, // custom formatting func
	summary: value => value,
	sortable: true, // boolean
	filterable: true, // boolean
	resizable: true, // boolean
	filter: {},
	state: {}
};
export const defaultColumnFilterSettings = {
	type: 'input', // input select date lookup textSelect (require data: string)
	data: 'String', // String Number Date
	key: null,
	options: [], // [{label: 'Google', value: 1}, {label: 'Facebook', value: 2}, {label: 'Twitter', value: 3}],
	optionValue: 'value', //for select/lookup
	optionLabel: 'label', //for select/lookup
	placeholder: '',
	mask: '',
	dateFormat: 'MM/DD/YYYY',
	dateMask: '##/##/####',
	rules: [],
	maxlength: 256,
	style: null,
	range: false,
	model: null,
	modelRangeFrom: null,
	modelRangeTo: null
};
export const defaultColumnStateSettings = {
	sortOrder: null, // string // null asc desc
	resize: {
		clientX: null,
		width: null,
		resizing: false
	}
};
const defaultPagination = {
	disable: false,
	position: 'bottom', // top both
	itemsPerPage: 10,
	currentPage: 1,
	itemsPerPageOptions: [ 10, 20, 30, 40, 50, 100 ]
};
const defaultProperSlots = {
	grid: [ 'before', 'start', 'end', 'after' ],
	header: [ 'batchActions', 'headerBefore', 'headerAfter', 'headerPrepend', 'headerAppend', 'headerStart', 'headerEnd' ],
	footer: [ 'footerBefore', 'footerStart', 'footerPrepend', 'footerAppend', 'footerEnd', 'footerAfter' ],
	columns: [ 'columnsRow' ],
	items: [ 'itemRow' ],
	summary: [ 'summaryFieldsRow' ]
};

const fieldsFilterTypes = {
	Boolean: {
		None: null,
		Equal: 'select',
		Contain: 'select',
		Range: 'select',
		In: 'lookup'
	},
	DateTime: {
		None: null,
		Equal: 'date',
		Contain: 'date',
		Range: 'date',
		In: 'lookup'
	},
	Decimal: {
		None: null,
		Equal: 'input',
		Contain: 'input',
		Range: 'input',
		In: 'lookup'
	},
	Float: {
		None: null,
		Equal: 'input',
		Contain: 'input',
		Range: 'input',
		In: 'lookup'
	},
	Guid: {
		None: null,
		Equal: 'input',
		Contain: 'input',
		Range: 'input',
		In: 'lookup'
	},
	Int32: {
		None: null,
		Equal: 'input',
		Contain: 'input',
		Range: 'input',
		In: 'lookup'
	},
	Int64: {
		None: null,
		Equal: 'input',
		Contain: 'input',
		Range: 'input',
		In: 'lookup'
	},
	String: {
		None: null,
		Equal: 'input',
		Contain: 'input',
		Range: 'input',
		In: 'lookup'
	}
};

const makeSeparatedStore = defineStore => {
	const definedStores = new Map();

	return storeKey => {
		if (!definedStores.has(storeKey)) {
			definedStores.set(storeKey, defineStore(storeKey));
		}

		return definedStores.get(storeKey)();
	};
};

export const useGridStore = makeSeparatedStore(key => defineStore(`gridStore${key}`, {
	state: () => ({
		gridId: null,
		gridItemIdKey: null,

		gridColumns: null,
		gridColumnGroups: null,
		gridItems: null,
		gridFields: null,
		gridItemsSelectedIds: [],
		gridItemsAllSelected: false,
		gridItemsHighlighted: null,
		lastUsedIdx: null,
		gridItemsTotal: null,

		gridItemSelectionEnable: true,
		gridSummaryFields: null,

		gridSkeletonCount: null,

		gridPagination: null,

		columnSortOrderMap: [ 'asc', 'desc', null ],
		properSlots: null,

		disableLocalConfig: false,

		progress: {
			loading: false,
			items: []
		},
		platform: {
			isMobile: Platform.is.mobile
		},
		state: {
			firstGridRequest: true,
			gridError: null,
			blink: null,
			expanded: [],
			sticky: {
				header: false,
				column: false
			},
			filters: {
				show: false
			}
		}
	}),
	getters: {
		columnsLength(state) {
			return state.gridColumns && state.gridColumns.length;
		},
		itemsLength(state) {
			return state.gridItems && state.gridItems.length;
		},
		itemsSelectedLength(state) {
			return state.gridItemsSelectedIds && state.gridItemsSelectedIds.length;
		},
		hasSelectedItems() {
			return !!this.itemsSelectedLength;
		},
		totalColumnsLength(state) {
			return state.gridItemSelectionEnable ? this.columnsLength + 1 : this.columnsLength;
		},
		hasGridItems(state) {
			return state.gridItems && !!state.gridItems.length;
		},
		gridItemsSelected(state) {
			return state.gridItemsSelectedIds.map(id => state.gridItems.find(item => item[state.gridItemIdKey] === id));
		},
		progressItemsLength(state) {
			return state.progress.items && state.progress.items.length;
		},
		gridItemsAllSelectedProgress() {
			return this.progressItemsLength === this.itemsLength;
		},
		emptyGridItems(state) {
			return state.gridItems && !state.gridItems.length && !state.progress.loading;
		},
		hasPropsItems(state) {
			return state.items && !!state.items.length;
		},
		hasGridSummaryFields(state) {
			const columns = state.gridColumns.map(column => column.name);
			const fields = state.gridSummaryFields ? Object.keys(state.gridSummaryFields) : [];

			return state.gridSummaryFields && fields.some(field => columns.includes(field));
		},
		columnSortOrderMapLength(state) {
			return state.columnSortOrderMap.length;
		},
		gridItemsTotalLine(state) {
			return state.gridItemsTotal ? `${state.gridItemsTotal} item${state.gridItemsTotal > 1 ? 's' : ''}` : null;
		},
		paginationPagesTotal(state) {
			if (!Number.isFinite(state.gridItemsTotal) || !Number.isFinite(state.gridPagination.itemsPerPage)) {
				return;
			}

			return Math.ceil(state.gridItemsTotal / state.gridPagination.itemsPerPage);
		},
		paginationPositionBottom(state) {
			return state.gridPagination.position === 'bottom';
		},
		paginationPositionTop(state) {
			return state.gridPagination.position === 'top';
		},
		paginationPositionBoth(state) {
			return state.gridPagination.position === 'both';
		},
		isStickyHeader(state) {
			return state.state.sticky.header;
		},
		isStickyColumn(state) {
			return state.state.sticky.column;
		},
		gridColumnFilters(state) {
			const filtersEntries = state.gridColumns.reduce((acc, column) => {
				const entries = !column.filterable ? [] : column.filter.range ? [
					[ formatGridColumnFilterValue(column.filter.modelRangeFrom, column.filter, true), `${column.field}_from` ],
					[ formatGridColumnFilterValue(column.filter.modelRangeTo, column.filter), `${column.field}_to` ]
				] : [
					[ formatGridColumnFilterValue(column.filter.model, column.filter), column.filter.key || column.field ]
				];

				return [ ...acc, ...entries ];
			}, []).filter(([ value ]) => value !== null && value !== '').map(entry => entry.reverse());

			return Object.fromEntries(filtersEntries);
		}
	},
	actions: {
		updateGridItems(items) {
			this.gridItems = items;
		},
		updateGridId(id) {
			this.gridId = id;
		},
		updateGridColumns(columns) {
			this.gridColumns = this.parseGridColumns(columns);
			this.properSlots = this.parseProperSlots(this.gridColumns);
		},
		parseGridColumns(columns) {
			const getField = column => this.gridFields ? this.gridFields[column.field] : {};
			const getFieldFilter = column => this.gridFields && this.gridFields[column.field] ? this.gridFields[column.field].filter : {};

			return columns.map(column => ({
				...defaultColumnSettings,
				...getField(column),
				...column,
				name: column.name || column.field,
				titleAlign: column.titleAlign || column.align || defaultColumnSettings.titleAlign,
				filter: { ...defaultColumnFilterSettings, ...getFieldFilter(column), ...column.filter },
				state: { ...defaultColumnStateSettings }
			}));
		},
		parseProperSlots(columns) {
			const properCellSlots = columns.reduce((acc, column) => ({
				columns: [ ...acc.columns, `column${column.name}` ],
				items: [ ...acc.items, `cell${column.name}` ],
				summary: [ ...acc.summary, `summaryFieldCell${column.name}` ]
			}), { columns: [], items: [], summary: [] });

			return {
				...defaultProperSlots,
				columns: [ ...defaultProperSlots.columns, ...properCellSlots.columns ],
				items: [ ...defaultProperSlots.items, ...properCellSlots.items ],
				summary: [ ...defaultProperSlots.summary, ...properCellSlots.summary ]
			};
		},
		resetColumnsSortOrder() {
			this.gridColumns.forEach(column => {
				if (!column.sortable) {
					return;
				}

				column.state.sortOrder = null;
			});
		},
		updateColumnResizeState(field, state) {
			const column = this.gridColumns.find(column => column.name === field);

			column.state.resize = { ...column.state.resize, ...state };
		},
		setColumnFilter(field, filter ) {
			const column = this.gridColumns.find(column => column.name === field);

			if (!column.filterable) {
				return;
			}

			column.filter = { ...column.filter, ...filter };
		},
		resetColumnsFilters() {
			this.gridColumns.forEach(column => {
				if (!column.filterable) {
					return;
				}

				const clearFilterModels = {
					model: null,
					modelRangeFrom: null,
					modelRangeTo: null
				};

				column.filter = { ...column.filter, ...clearFilterModels };
			});
		},
		updateGridColumnGroups(groups) {
			this.gridColumnGroups = this.parseGridColumnGroups(groups);
		},
		parseGridColumnGroups(groups) {
			return groups.map(row => row.map(group => ({ ...defaultColumnGroupSettings, ...group })));
		},
		updateGridPagination(paginationOptions = {}) {
			this.gridPagination = { ...defaultPagination, ...paginationOptions };
		},
		updatePaginationCurrentPage(pageNumber) {
			this.gridPagination.currentPage = pageNumber;
		},
		showGridError(message) {
			this.state.gridError = message;

			console.error(this.state.gridError);
		},
		resetGridError() {
			this.state.gridError = null;
		},
		resetFirstGridRequest() {
			this.state.firstGridRequest = true;
		},
		updateItemSelectionEnable(state) {
			this.gridItemSelectionEnable = state;
		},
		updateItemsAllSelected(value) {
			this.gridItemsAllSelected = value === undefined || value === null ? this.gridItemsAllSelected = this.itemsSelectedLength
				? this.itemsSelectedLength === this.itemsLength || 'partial'
				: false : value;

			if (!this.gridItemsAllSelected) {
				this.clearLastUsedIdx();
			}
		},
		updateGridSkeletonCount(value) {
			this.gridSkeletonCount = value;
		},
		updateGridFields(fields) {
			this.gridFields = fields ? this.parseGridFields(fields) : null;

			if (!this.gridFields) {
				console.error('Can\'t fetch grid fields');
			}
		},
		parseGridFields(fields) {
			return fields.reduce((acc, item) => {
				const field = {
					...item,
					FilterType: item.FilterType || 'None',
					Type: item.Type.replace('?', '')
				};

				return {
					...acc,
					[field.Name]: {
						title: field.Title,
						sortable: field.OrderBy,
						filterable: field.FilterType !== 'None',
						filter: {
							type: fieldsFilterTypes[field.Type][field.FilterType],
							data: field.Type,
							range: field.FilterType === 'Range'
						}
					}
				};
			}, {});
		},
		updateProgressLoading(state) {
			this.progress.loading = state;
		},
		updateProgressItems(values) {
			this.progress.items = values;
		},
		updateStateBlink(values) {
			this.state.blink = values;

			if (values && values.length) {
				const [ itemId ] = values;

				this.setItemHighlighted(itemId);
			}
		},
		isGridItemBlink(itemId) {
			return this.state.blink && this.state.blink.includes(itemId);
		},
		isGridItemInProgress(itemId) {
			return this.progress.items && this.progress.items.includes(itemId);
		},
		isGridItemSelected(itemId) {
			return this.gridItemsSelectedIds.includes(itemId);
		},
		isGridItemExpanded(itemId) {
			return this.state.expanded && this.state.expanded.includes(itemId);
		},
		updateStateExpanded(itemId) {
			this.state.expanded = this.isGridItemExpanded(itemId) ? this.state.expanded.filter(id => id !== itemId) : [ ...this.state.expanded, itemId ];
		},
		updateItemsSelected(state) {
			this.gridItemsSelectedIds = state;
		},
		updateGridItemsSelectedIds(itemId, idx) {
			if (!this.gridItemSelectionEnable) {
				return;
			}

			!this.isGridItemSelected(itemId) ? this.addGridItemsSelectedIds(itemId) : this.removeGridItemsSelectedIds(itemId);

			this.updateItemsAllSelected();
			this.setLastUsedIdx(idx);
		},
		addGridItemsSelectedIds(itemId) {
			this.gridItemsSelectedIds = [ ...this.gridItemsSelectedIds, itemId ];
		},
		removeGridItemsSelectedIds(itemId) {
			this.gridItemsSelectedIds = this.gridItemsSelectedIds.filter(id => id !== itemId);
		},

		getFirstSelectedItemIdx() {
			const [ firstSelectedId ] = this.gridItemsSelectedIds;

			return this.gridItems.findIndex(item => this.getItemId(item) === firstSelectedId);
		},
		getLastSelectedItemIdx() {
			const lastSelectedId = this.gridItemsSelectedIds.at(-1);

			return this.gridItems.findIndex(item => this.getItemId(item) === lastSelectedId);
		},
		selectItemByIndex(itemIdx) {
			const target = this.gridItems[itemIdx];
			const targetId = this.getItemId(target);

			if (!this.isGridItemSelected(targetId)) {
				this.addGridItemsSelectedIds(targetId);
			}
		},
		rangeGridItemsSelectedIds(itemId, idx) {
			if (!this.gridItemSelectionEnable) {
				return;
			}

			this.updateItemsSelected([]);

			const firstSelected = this.getFirstSelectedItemIdx();
			const startIndex = this.lastUsedIdx || (firstSelected >= 0 ? firstSelected : 0);

			if (!this.isGridItemSelected(itemId)) {
				this.addGridItemsSelectedIds(itemId);
			}

			const endIndex = this.getLastSelectedItemIdx();

			if (startIndex < endIndex) {
				for (let i = startIndex; i <= endIndex; i++) {
					this.selectItemByIndex(i);
				}
			} else {
				for (let i = startIndex; i >= endIndex; i--) {
					this.selectItemByIndex(i);
				}
			}

			this.updateItemsAllSelected();
			this.setLastUsedIdx(idx);

			document.getSelection().removeAllRanges();
		},
		setLastUsedIdx(idx) {
			this.lastUsedIdx = idx;
		},
		clearLastUsedIdx() {
			this.lastUsedIdx = null;
		},
		isGridItemHighlighted(itemId) {
			return this.gridItemsHighlighted === itemId;
		},
		setItemHighlighted(itemId) {
			this.gridItemsHighlighted = !this.isGridItemHighlighted(itemId) ? itemId : null;
		},
		getCurrentHighlightedItemIdx() {
			if (!this.hasGridItems) {
				return null;
			}

			const [ firstItem ] = this.gridItems;
			const currentItemId = this.gridItemsHighlighted || this.getItemId(firstItem);
			const currentItemIdx = this.gridItems.findIndex(item => this.getItemId(item) === currentItemId);

			if (!this.gridItemsHighlighted && !currentItemIdx) {
				this.setItemHighlighted(currentItemId);

				return null;
			}

			return currentItemIdx;
		},

		prevHighlightedItem() {
			const currentItemIdx = this.getCurrentHighlightedItemIdx();

			if (currentItemIdx === null) {
				return;
			}

			const prevItem = this.gridItems[currentItemIdx - 1];

			if (prevItem) {
				this.setItemHighlighted(this.getItemId(prevItem));
			}
		},
		nextHighlightedItem() {
			const currentItemIdx = this.getCurrentHighlightedItemIdx();

			if (currentItemIdx === null) {
				return;
			}

			const nextItem = this.gridItems[currentItemIdx + 1];

			if (nextItem) {
				this.setItemHighlighted(this.getItemId(nextItem));
			}
		},
		toggleHighlightedItem() {
			const idx = this.getCurrentHighlightedItemIdx();
			const item = this.gridItems[idx];

			if (!item) {
				return;
			}

			this.updateGridItemsSelectedIds(this.getItemId(item), idx);
		},

		updateFiltersShow(state) {
			this.state.filters.show = state;
		},
		updateGridItemIdKey(id) {
			this.gridItemIdKey = id;
		},
		updateGridStickyHeader(state) {
			this.state.sticky.header = state;
		},
		updateGridStickyColumn(state) {
			this.state.sticky.column = state;
		},
		getItemId(item) {
			return item[this.gridItemIdKey];
		},

		// Local Storage actions

		setLocalConfigDisable(state) {
			this.disableLocalConfig = state;
		},
		updateLocalGridConfig() {
			if (!this.gridId || this.disableLocalConfig) {
				return;
			}

			const { itemsPerPage } = this.gridPagination;
			const { show } = this.state.filters;
			const config = { itemsPerPage, filtersShow: show };
			const storageName = `__${this.gridId}_LocalConfig`;

			LocalStorage.set(storageName, config);
		},
		getLocalGridConfig() {
			const storageName = `__${this.gridId}_LocalConfig`;
			const config = LocalStorage.getItem(storageName);

			return this.gridId && !this.disableLocalConfig && config ? config : {};
		}
	}
}));

export default useGridStore;
