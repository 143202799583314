<template>
	<tbody>
	<tr>
		<td :colspan="store.totalColumnsLength" class="grid-empty-message">
			<Alert
				outline
				color="grey-1"
				message="No data available in list."
			/>
		</td>
	</tr>
	</tbody>
</template>

<script setup>
import { inject } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';
import Alert from '@components/alert';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
</script>

<style lang="scss" scoped>
td {
	vertical-align: initial;
	padding: 6px;
}
</style>
