import dateUtils from 'quasar/src/utils/date.js';;
import { typeIsNumber, typeIsString } from './type';

export const formatters = {
	date: (value, params) => {
		const defaultInitialFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
		const defaultOutputFormat = 'MM/DD/YYYY hh:mm A';
		const options = params
			? typeIsString(params)
				? { initialFormat: defaultInitialFormat, outputFormat: params }
				: { initialFormat: params.initialFormat, outputFormat: params.outputFormat }
			: null;
		const date = params && params.initialFormat ? dateUtils.extractDate(value, options.initialFormat) : new Date(value);
		const outputFormat = options ? options.outputFormat || defaultOutputFormat : defaultOutputFormat;

		return dateUtils.formatDate(date.getTime(), outputFormat);
	},
	dateToUtc: value => {
		const date = new Date(value);
		const utcTime = date.getTime() + (date.getTimezoneOffset() * 60 * 1000 );
		
		return `${dateUtils.formatDate(utcTime, 'YYYY-MM-DDTHH:mm:ss')}Z`;
	},
	phone: (value) => {
		if( !value ){
			return '';
		}

		const phoneTest = new RegExp(/^((\+)\d)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

		value = value.trim();

		const results = phoneTest.exec(value);

		return results !== null && results.length > 8 ? `(${results[3]}) ${results[4]} - ${results[5]}${(typeof results[8] !== 'undefined' ? ` x${results[8]}` : '')}` : value;
	},
	money: (value, params) => {
		const validValueType = typeIsNumber(value) || typeIsString(value);
		const options = { ...{ currency: '$', position: 'left', separator: ',' }, ...params };
		const format = (value, options) => {
			let result = Math.abs(value).toFixed(3).replace(/\d$/, '');

			if (options.separator) {
				result = result.replace(/(\d)(?=(\d{3})+\.)/g, `$1${options.separator}`);
			}

			if (options.currency) {
				result = options.position === 'left' ? `${options.currency}${result}` : `${result}${options.currency}`;
			}

			if (value < 0) {
				result = `-${result}`;
			}

			return result;
		};

		if (!validValueType) {
			return '';
		}

		if (isNaN(value)) {
			throw new Error(`Value is not a number: ${value}`);
		}

		return format(value, options);
	}
};
