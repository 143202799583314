import script from "./Errors.vue?vue&type=script&setup=true&lang=js"
export * from "./Errors.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QLinearProgress,QList,QItem,QItemSection});
