<template>
	<div class="column settings-tab-content">
		<div v-if="manufacturers && manufacturers.length" class="q-gutter-md">
			<div class="row gap-md">
				<div class="col-12 col-md column gap-md">
					<q-select
							outlined dense use-input
							fill-input hide-selected
							:model-value="manufacturer"
							:options="manufacturerOptions"
							option-value="id"
							option-label="name"
							label="Manufacturer"
							@filter="filterFn"
							@update:model-value="setModel"
					>
						<template v-slot:no-option>
							<q-item>
								<q-item-section class="text-grey">
									No results
								</q-item-section>
							</q-item>
						</template>
					</q-select>
					<div v-if="settings" class="row gap-sm">
						<q-checkbox
								v-model="settings.splitAccessories"
								:disable="!manufacturer || progressUpdateSettings"
								label="Split accessories"
								@update:model-value="updateSettings"
						/>
						<q-checkbox
								v-model="settings.extractMandatoryPrice"
								:disable="!manufacturer || progressUpdateSettings"
								label="Use price from Option"
								@update:model-value="updateSettings"
						/>
						<div class="info-settings-text">
							<q-input
									v-model="settings.daysBeforeUpdatePrice"
									type="number"
									ref="daysBeforeUpdatePriceInput"
									:rules="daysBeforeUpdatePriceRules"
									:disable="!manufacturer || progressUpdateSettings"
									min="0"
									class="field"
									required dense
									@change="updateSettings"
							/>
							<p class="message">
								{{ 'Days before update price' }}
							</p>
						</div>

						<q-checkbox
							v-model="settings.allowNet100Price"
							:disable="!manufacturer || progressUpdateSettings"
							label="Allow Net 10 Price"
							@update:model-value="updateSettings"
						/>
					</div>
				</div>
				<div class="column items-stretch col-12 col-md row-md content-md-start gap-md">
					<CrmDialog v-model="stateUpdateOption">
						<template #title>
							Success
						</template>
						<template #content>
							Brand successfully launched for update
						</template>
						<template #actions>
							<q-btn unelevated no-caps
								   label="Ok"
								   color="positive"
								   @click="hideDialogUpdateOption"
							/>
							<q-btn unelevated no-caps
								   label="Go to tasks"
								   color="info"
								   @click="goToTask"
							/>
						</template>
					</CrmDialog>
					<CrmDialog v-model="stateUpdatePrices">
						<template #title>
							Success
						</template>
						<template #content>
							Price successfully launched for update
						</template>
						<template #actions>
							<q-btn unelevated no-caps
								   label="Ok"
								   color="positive"
								   @click="hideDialogUpdatePrices"
							/>
							<q-btn unelevated no-caps
								   label="Go to tasks"
								   color="info"
								   @click="goToTask"
							/>
						</template>
					</CrmDialog>
					<CrmDialog v-model="statePullProductInfo">
						<template #title>
							Task was started
						</template>
						<template #actions>
							<q-btn unelevated no-caps
								   label="Ok"
								   color="positive"
								   @click="hideDialogProductInfoFromAq"
							/>
							<q-btn unelevated no-caps
								   label="Go to tasks"
								   color="info"
								   @click="goToTask"
							/>
						</template>
					</CrmDialog>
					<CrmDialog v-model="stateImportNewProducts">
						<template #title>
							Task was started
						</template>
						<template #actions>
							<q-btn unelevated no-caps
								   label="Ok"
								   color="positive"
								   @click="hideDialogImportNewProducts"
							/>
							<q-btn unelevated no-caps
								   label="Go to tasks"
								   color="info"
								   @click="goToTask"
							/>
						</template>
					</CrmDialog>
					<q-btn
							unelevated no-caps outline
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							:loading="progressPullProductInfo"
							:disable="!manufacturer"
							label="Pull Product Info from Aq"
							@click="pullProductInfoFromAq"
					/>
					<q-btn
							unelevated no-caps outline
							color="white"
							text-color="black"
							label="Update Options"
							class="q-btn--outline-gray"
							:loading="progressUpdateOption"
							:disable="!manufacturer"
							@click="updateOptions"
					/>
					<q-btn
							unelevated no-caps outline
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							:loading="progressUpdatePrices"
							:disable="!manufacturer"
							label="Update Prices"
							@click="updatePrices"
					/>
					<q-btn
							unelevated no-caps outline
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							:loading="progressImportNewProducts"
							:disable="!manufacturer"
							label="Import New Products"
							@click="importNewProducts"
					/>
					<span v-if="lastUpdateMsg" class="">
						Last update: {{ formatters.date(manufacturer.crmLastPushAt, 'MM/DD/YYYY')}} by {{manufacturer.crmLastPushBy}}
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-5">
					<aq-product-settings-list
							:list="options"
							subtitle="Options"
							:loading="progressLists"
					></aq-product-settings-list>
				</div>
				<div class="col-12 col-md-2 flex items-center justify-center q-py-md">
					<q-btn
							unelevated no-caps outline
							color="white"
							text-color="black"
							class="q-btn--outline-gray"
							:icon="isMobile ? 'mdi-arrow-down' : 'mdi-arrow-right'"
							:loading="progressAddBtn"
							:disable="disabledBtns || !isSelectedOption"
							@click="addBtnHandler"
					/>
				</div>
				<div class="col-12 col-md-5">
					<aq-product-settings-list
							:list="blackList"
							subtitle="Options will be skipped for an update"
							:loading="progressLists"
							@remove="removeHandler"
							@update="updateHandler"
							@cancel-edit="cancelHandler"
					></aq-product-settings-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {ref, onMounted, watch, computed, inject} from 'vue';
import { useRouter } from 'vue-router';
const { $showNotify } = inject('$app');

import api from "@/api";
import AqProductSettingsList from "@/views/tabs/settings/list";
import CrmDialog from '@/components/dialog';
import { formatters } from '@components/grid';
import { useManufacturersStore } from '@/store';

import { Platform } from 'quasar';
const iframeSyncClient = inject('iframeSyncClient');

const router = useRouter();
const manufacturersStore = useManufacturersStore();
const daysBeforeUpdatePriceRules = [v => (v.length >= 0 && Number(v) >= 0) || 'Required'];

const settings = ref(null);
const manufacturers = ref([]);
const manufacturerOptions = ref([]);
const options = ref([]);
const blackList = ref([]);
const manufacturer = ref(null);
const currentTaskId = ref(null);

const stateUpdateOption = ref(false);
const stateUpdatePrices = ref(false);
const statePullProductInfo = ref(false);
const stateImportNewProducts = ref(false);

const progressUpdateOption = ref(false);
const progressUpdatePrices = ref(false);
const progressUpdateSettings = ref(false);
const progressImportNewProducts = ref(false);
const progressPullProductInfo = ref(false);
const progressLists = ref(false);
const progressAddBtn = ref(false);

const isMobile = computed(() => Platform.is.mobile);
const disabledBtns = computed(() => progressAddBtn.value || progressLists.value);
const isSelectedOption = computed(() => {
	if (!options.value) {
		return true;
	}

	const allOptions = options.value.reduce((acc, option) => ([...acc, option, ...option.values]), []);

	return allOptions.filter(item => item.select).length;
});
const lastUpdateMsg = computed(() => manufacturer.value && manufacturer.value.crmLastPushAt);
const filterFn = (val, update) => {
	update(() => {
		const needle = val && val.toLowerCase();

		manufacturerOptions.value = manufacturers.value.filter(option => option.name.toLowerCase().indexOf(needle) > -1);
	});
};
const setModel = (val) => {
	manufacturer.value = val;
};
const getManufacturers = async () => {
	if (!manufacturersStore.manufacturers.length) {
		iframeSyncClient.sendMessage({ type: 'loading', state: true });

		try {
			const manufacturers = await api.fetchManufacturers();

			manufacturersStore.updateManufacturers(manufacturers);
		} finally {
			iframeSyncClient.sendMessage({ type: 'loading', state: false });
		}
	}
	manufacturers.value = manufacturersStore.manufacturers;
	manufacturerOptions.value = manufacturersStore.manufacturers;
};
const updateOptionsSettings = async () => {
	const { id: manufacturerId } = manufacturer.value;

	settings.value = await api.fetchOptionsSettings(manufacturerId);
};
const fetchBlackList = async () => {
	const { id: manufacturerId } = manufacturer.value;

	const blackListData = await api.fetchBlackList(manufacturerId);

	blackList.value = blackListData.map(item => ({description: item, select: false, oldDescription: item}));
};
const getManufacturerOptions = async () => {
	if (!manufacturer.value) {
		return;
	}

	const { id: manufacturerId } = manufacturer.value;

	progressLists.value = true;

	try {
		const optionsList = await api.fetchOptionList(manufacturerId);
		await fetchBlackList();
		await updateOptionsSettings();

		options.value = optionsList.map(item => {
			const values = item.values && item.values.length ? item.values.map(val => ({description: val, select: false, value: `${item.description}/${val}`})) : [];

			return {...item, value: `${item.description}/*`, values, select: false};
		});
	}  finally {
		progressLists.value = false;
	}
};
const addBtnHandler = async () => {
	const allOptions = options.value.reduce((acc, option) => ([...acc, option, ...option.values]), []);
	const selectItem = allOptions.filter(item => item.select).map(item => item.value);
	const data = blackList.value.map(item => item.description).concat(selectItem);

	progressAddBtn.value = true;

	try {
		await updateBlackList(data)
	} finally {
		progressAddBtn.value = false;
		getManufacturerOptions();
	}
};
const removeHandler = async (inx) => {
	blackList.value.splice(inx, 1);

	const data = blackList.value.map(item => item.description);

	try {
		await updateBlackList(data)
	} finally {
		await fetchBlackList();
		$showNotify({ type: 'positive', message: 'Options will be skipped for update successfully saved', timeout: 1000 });
	}
};
const updateHandler = async () => {
	const data = blackList.value.map(item => item.description);

	try {
		await updateBlackList(data);
	} finally {
		await fetchBlackList();
		$showNotify({ type: 'positive', message: 'Options will be skipped for update successfully saved', timeout: 1000 });
	}
};
const cancelHandler = async () => {
	blackList.value = blackList.value.map(item => ({...item, description: item.oldDescription}));
};
const updateBlackList = async (data) => {
	const { id: manufacturerId } = manufacturer.value;

	await api.putBlackList(manufacturerId, data);
};
const showDialogUpdatePrices = taskId => {
	stateUpdatePrices.value = true;
	currentTaskId.value = taskId;
};
const hideDialogUpdatePrices = () => {
	stateUpdatePrices.value = false;
	currentTaskId.value = null;
};
const updatePrices = async () => {
	const { id: manufacturerId } = manufacturer.value;

	progressUpdatePrices.value = true;

	try {
		const taskId = await api.postUpdatePricesSettings(manufacturerId);

		showDialogUpdatePrices(taskId);
	} finally {
		progressUpdatePrices.value = false;
	}
};
const showDialogImportNewProducts = taskId => {
	stateImportNewProducts.value = true;
	currentTaskId.value = taskId;
};
const hideDialogImportNewProducts = () => {
	stateImportNewProducts.value = false;
	currentTaskId.value = null;
};
const importNewProducts = async () => {
	const { id: manufacturerId } = manufacturer.value;

	progressImportNewProducts.value = true;

	try {
		const taskId = await api.fetchImportNewProducts(manufacturerId);

		showDialogImportNewProducts(taskId);
	} finally {
		progressImportNewProducts.value = false;
	}
};

const goToTask = () => {
	const taskId = currentTaskId.value;

	hideDialogProductInfoFromAq();
	hideDialogUpdateOption();
	hideDialogUpdatePrices();
	hideDialogImportNewProducts();

	router.push({ name: 'Tasks', query: { taskId } });
};
const showDialogProductInfoFromAq = taskId => {
	statePullProductInfo.value = true;
	currentTaskId.value = taskId;
};
const hideDialogProductInfoFromAq = () => {
	statePullProductInfo.value = false;
	currentTaskId.value = null;
};
const pullProductInfoFromAq = async () => {
	const { id: manufacturerId } = manufacturer.value;

	progressPullProductInfo.value = true;

	try {
		const taskId = await api.fetchProductInfoFromAqSettings(manufacturerId);

		showDialogProductInfoFromAq(taskId);
	} finally {
		progressPullProductInfo.value = false;
	}
};

const showDialogUpdateOption = taskId => {
	stateUpdateOption.value = true;
	currentTaskId.value = taskId;
};
const hideDialogUpdateOption = () => {
	stateUpdateOption.value = false;
	currentTaskId.value = null;
};
const updateOptions = async () => {
	const { id: manufacturerId } = manufacturer.value;

	progressUpdateOption.value = true;

	try {
		const taskId = await api.postUpdateOptionsSettings(manufacturerId)

		showDialogUpdateOption(taskId);
	} finally {
		progressUpdateOption.value = false;
	}
};
const updateSettings = async () => {
	const { id: manufacturerId } = manufacturer.value;
	const { splitAccessories, extractMandatoryPrice, daysBeforeUpdatePrice, allowNet100Price } = settings.value;

	progressUpdateSettings.value = true;

	try {
		await api.putOptionsSettings(manufacturerId, {splitAccessories, extractMandatoryPrice, daysBeforeUpdatePrice, allowNet100Price})
	} catch (e) {
		updateOptionsSettings();
	} finally {
		progressUpdateSettings.value = false;
	}
};

watch(() => manufacturer.value,() => {
	getManufacturerOptions();
});

onMounted(() => {
	getManufacturers();
});

</script>
<style lang="scss">
.field {
	input {
		padding-top: 16px;
	}
}
</style>

<style lang="scss" scoped>
.w-100 {
	width: 100%;
}

.info-settings-text {
	display: flex;
	align-items: center;
	margin-left: 12px;
	padding-top: 8px;

	.field {
		width: 70px;

		input {
			padding-top: 16px;
		}
	}
	.message {
		padding-top: 4px;
	}
}

.row-md {
	@media screen and (width >= 1024px) {
		flex-direction: row;
		align-items: center;
	}
}
.content-md-start {
	@media screen and (width >= 1024px) {
		align-content: start;
	}
}

</style>
