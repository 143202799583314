import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import EventBus from 'quasar/src/utils/EventBus.js';;

import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/mdi-v6/mdi-v6.css';
import 'quasar/src/css/index.sass';
import '@styles/global.scss';

import quasarConfig from '../config/quasar-config';
import AppCommonPlugin from '../plugins/common';
import { useIframeSyncClient } from "../hooks/iframe-sync";

export default class CrmApp {
	isProductionMode = false;

	app = null;
	pinia = null;
	router = null;

	userId = null;
	siteId = null;
	sessionId = null;

	#App = null;
	#mount = null;
	#plugins = null;

	constructor({ App, pinia, router, mount = '#app', plugins = null }) {
		this.pinia = pinia;
		this.router = router;

		this.#App = App;
		this.#mount = mount;
		this.#plugins = plugins;

		this.#setMode();
		this.#setInitialParams();

		this.initApp();
	}

	get instance() {
		return this.app;
	}

	#setInitialParams() {
		const searchParams = new URLSearchParams(window.location.search);
		const { user_id, site_id, session_id, origin } = Object.fromEntries(searchParams.entries());

		this.userId = user_id;
		this.siteId = site_id;
		this.sessionId = session_id;
		this.origin = origin;
	}

	#setMode() {
		this.isProductionMode = process.env.NODE_ENV === 'production';
	}

	#setEventBus() {
		if (!this.app) {
			console.error('Vue app is not initialized.');

			return;
		}

		// https://quasar.dev/quasar-utils/event-bus-util#usage
		const bus = new EventBus();

		this.app.config.globalProperties.$bus = bus;
		this.app.provide('bus', bus);
	}

	#setErrorHandler() {
		if (this.isProductionMode) {
			this.app.config.errorHandler = (err, vm, info) => {
				try {
					window.parentIFrame.sendMessage({
						type: 'error',
						data: { message: err.message, stack: err.stack, info }
					});
				} catch (err) {
					console.error(err);
				}
			};
		}
	}

	#setAdditionalPlugins() {
		if (this.app && this.#plugins && !!this.#plugins.length) {
			this.#plugins.forEach(entry => {
				if (Array.isArray(entry)) {
					const [ plugin, config ] = entry;

					this.app.use(plugin, config);
				} else {
					this.app.use(entry);
				}
			});
		}
	}

	initApp() {
		const { router, userId, siteId, sessionId, origin } = this;

		this.app = createApp(this.#App, { userId, siteId, sessionId, origin })
			.use(this.pinia)
			.use(router)
			.use(Quasar, quasarConfig)
			.use(AppCommonPlugin);

		this.#setAdditionalPlugins();

		this.#setEventBus();
		this.#setErrorHandler();

		const iframeSyncClient = useIframeSyncClient({ router, origin }); ///for prod
		// const iframeSyncClient = useIframeSyncClient({ router, origin: 'https://cko.local.4ib.co' }); /// for dev

		this.app.provide('iframeSyncClient', iframeSyncClient);
		this.app.mount(this.#mount);
	}
}
