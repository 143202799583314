<template>
	<q-layout view="hHh lpR fFf">
		<q-page-container>
			<router-view/>
		</q-page-container>
	</q-layout>
</template>

<style>
body {
	overflow: hidden;
}
</style>
