<template>
	<q-card
			flat bordered
			class="task-errors-content"
	>
		<q-card-section>
			<q-linear-progress v-if="taskErrorsProgress" indeterminate class="absolute" />
			<q-list v-else-if="errorsList && errorsList.length" class="rounded-borders">
				<q-item
						v-for="(error, idx) in errorsList"
						:key="`error-list-${idx}`"
				>
					<q-item-section>{{ error }}</q-item-section>
				</q-item>
			</q-list>
			<Alert
					v-else outline
					color="grey-1"
					message="Task completed without errors"
			/>
		</q-card-section>
	</q-card>
</template>

<script setup>
import { ref } from 'vue';
import api from '@/api';
import Alert from '@components/alert/index.vue';

const taskErrorsProgress = ref(true);
const errorsList = ref([]);

const props = defineProps({
	taskId: {
		type: String,
		required: true,
	}
})

const fetchTaskErrors = () => {
	const { taskId } = props;

	taskErrorsProgress.value = true;

	try {
		errorsList.value  = api.fetchTaskErrors(taskId);
	} finally {
		taskErrorsProgress.value = false;
	}
}

fetchTaskErrors();

</script>
