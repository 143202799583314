import deepMergeObject from 'quasar/src/utils/extend.js';;

export const deepColumnsMerge = (target, ...sources) => {
	if (!sources.length) {
		return target;
	}

	const source = sources.shift();
	const flat = [...target, ...deepColumnsMerge(source, ...sources)];
	const merge = flat.reduce((acc, column) => ({ ...acc, [column.field]: !acc[column.field] ? column : deepMergeObject(true, acc[column.field], column)}), {});

	return Object.values(merge);
};

export const columnsMerge = (definitions, columns) => definitions.map(definition => {
	const column = columns.find(column => column.field === definition.field);

	return column ? deepMergeObject(true, definition, column) : definition;
});
