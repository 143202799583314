<template>
	<tr>
		<th :colspan="store.totalColumnsLength" class="progress-head-line">
			<q-linear-progress v-if="store.progress.loading" indeterminate class="absolute" />
		</th>
	</tr>
</template>

<script setup>
import { inject } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
</script>

<style lang="scss" scoped>
tr {
	th {
		&.progress-head-line {
			position: relative;
			vertical-align: middle;
			background-color: transparent;
			border-color: transparent;
			padding: 0;
		}
	}
}
</style>
