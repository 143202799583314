<template>
	<div class="products-list-tab-content">
		<Grid v-if="!!gridConfig" ref="grid" v-bind="gridConfig" >
			<template #columnFiltermanufacturerName="{ column }">
				<SelectControl
						:filter="column.filter"
						:column="column"
						model-key="model"
						@update:model-value="changeFilter($event, 'manufacturerName')"
				>
					<template v-if="column.filter.model" #clear>
						<q-icon name="mdi-close" class="cursor-pointer" @click.stop.prevent="clearFilter(column)"/>
					</template>
					<template #no-option>
						<q-item>
							<q-item-section class="text-grey">
								No results
							</q-item-section>
						</q-item>
					</template>
				</SelectControl>
			</template>
			<template #columnFilterdiscountCategoryName="{ column }">
				<SelectControl
						:filter="{...column.filter, ...discountCategoryFilter}"
						:column="column"
						model-key="model"
						@update:model-value="changeFilter($event, 'discountCategoryName')"
				>
					<template v-if="column.filter.model" #clear>
						<q-icon name="mdi-close" class="cursor-pointer" @click.stop.prevent="column.filter.model = null"/>
					</template>
					<template #no-option>
						<q-item>
							<q-item-section class="text-grey">
								No results
							</q-item-section>
						</q-item>
					</template>
				</SelectControl>
			</template>
			<template #columnFilterignoreItem="{ column }">
				<SelectControl
						:filter="column.filter"
						:column="column"
						model-key="model"
						@update:model-value="changeFilter($event, 'ignoreItem')"
				>
					<template v-if="column.filter.model" #clear>
						<q-icon name="mdi-close" class="cursor-pointer" @click.stop.prevent="column.filter.model = null"/>
					</template>
				</SelectControl>
			</template>
			<template #batchActions>
				<q-list>
					<q-item v-close-popup clickable @click="addToCrm">
						<q-item-section>
							<q-item-label>Add to CRM</q-item-label>
						</q-item-section>
					</q-item>
					<q-item v-close-popup clickable @click="batchActionIgnore">
						<q-item-section>
							<q-item-label>Ignore</q-item-label>
						</q-item-section>
					</q-item>
					<q-item v-close-popup clickable @click="batchActionNotIgnore">
						<q-item-section>
							<q-item-label>Not Ignore</q-item-label>
						</q-item-section>
					</q-item>
					<q-item v-close-popup clickable @click="showSetMarginDialog">
						<q-item-section>
							<q-item-label>Set Margin</q-item-label>
						</q-item-section>
					</q-item>
				</q-list>
			</template>

			<template #cellmodel="{ item }">
				<span class="white-space-nowrap" v-html="getSku(item)"></span>
			</template>
			<template #cellnotInCrm="{ value }">
				<q-icon v-if="value" name="mdi-check" color="positive" size="24px" />
			</template>

			<template #cellfreezeItem="{ value, item }">
				<q-toggle :model-value="value" @update:model-value="updateProduct(item)" size="sm"/>
			</template>

			<template #cellignoreItem="{ value, item }">
				<q-toggle :model-value="value" @update:model-value="updateItemIgnore(item)" size="sm"/>
			</template>
		</Grid>
		<CrmDialog v-model="stateAddToCrm" position="top">
			<template #title>
				{{ progressAddToCrm ? '' : 'Success' }}
			</template>
			<template #content>
				<div v-if="progressAddToCrm" class="text-center" style="width: 470px;">
					<q-circular-progress
							indeterminate
							rounded
							size="50px"
							color="cyan"
					/>
				</div>
				<span v-else>Products have been successfully added to CRM</span>
			</template>
			<template #actions>
				<q-btn flat no-caps
					   label="Ok"
					   color="positive"
					   :disable="progressAddToCrm"
					   @click="hideDialogAddToCrm"
				/>
			</template>
		</CrmDialog>
		<CrmDialog v-model="stateSetMargin" position="top">
			<template #title>
				Set Margin
			</template>
			<template #content>
				<div style="width: 450px">
					<q-input outlined dense
							 v-model="currentMargin"
							 label="Margin"
							 suffix="%"
							 :disable="progressSetMargin"
					/>
				</div>
			</template>
			<template #actions>
				<q-btn v-close-popup flat no-caps
					   label="Cancel"
					   :disable="progressSetMargin"
				/>
				<q-btn flat no-caps
					   label="Ok"
					   color="positive"
					   :loading="progressSetMargin"
					   :disable="progressSetMargin || !currentMargin"
					   @click="sendBatchMargin"
				/>
			</template>
		</CrmDialog>
	</div>
</template>

<script>
import { Notify } from 'quasar';
import { ref, inject } from 'vue';

import Grid, { formatters } from '@components/grid';
import SelectControl from '@components/grid/components/table/filter/SelectControl';
import api from '@/api';
import CrmDialog from '@/components/dialog';
import { useManufacturersStore } from '@/store';

export default {
	components: {
		Grid,
		CrmDialog,
		SelectControl
	},
	beforeRouteEnter(to, from, next) {
		const { serviceId } = from.params;

		if (!serviceId) {
			next();
		} else {
			next(vm => vm.highlightGridItem(serviceId));
		}
	},
	setup(props, { expose }) {
		const iframeSyncClient = inject('iframeSyncClient');
		const manufacturersStore = useManufacturersStore();
		const grid = ref(null);
		const gridConfig = ref(null);
		const stateAddToCrm = ref(false);
		const progressAddToCrm = ref(false);
		const discountCategory = ref([]);

		const stateSetMargin = ref(false);
		const progressSetMargin = ref(false);
		const currentMargin = ref(null);

		const YesNoLookup = [
			{ label: 'Yes', value: true },
			{ label: 'No', value: false }
		];


		const showDialogAddToCrm = () => {
			stateAddToCrm.value = true;
		};
		const hideDialogAddToCrm = () => {
			stateAddToCrm.value = false;
		};
		const getSku = item => {
			const text = `<span>${item.model}</span>`

			if (!window.parentIFrame) {
				return text;
			}

			const {origin} = window.parentIFrame;
			const url = origin.replace('aq-products', '');
			const link = `<a href="${url}inventory#/product/${item.crmProductId}/product-general" target="_blank">${item.model}</a>`

			return item.crmProductId ? link : text;
		};
		const addToCrm = async () => {
			const mode = 'AddProductToCrm';
			const { gridItemsSelectedIds: selectedProduct } = grid.value.gridStore;

			showDialogAddToCrm();

			progressAddToCrm.value = true;

			try {
				await api.postProductsCommandBatch({mode}, selectedProduct)
			} catch (error) {
				hideDialogAddToCrm();
			} finally {
				progressAddToCrm.value = false;

				grid.value.refreshGrid();
				grid.value.resetItemsProgress();
			}
		};
		const updateProduct = async item => {
			const {productId: product_id, freezeItem: freeze_item} = item;

			try {
				await api.putProductsConfig(product_id, { freeze_item: !freeze_item})
			} finally {
				grid.value.refreshGrid();
				grid.value.resetItemsProgress();
			}
		};
		const sendIgnoreProduct = async (productIds, ignore) => {
			if (!productIds || !productIds.length) {
				return;
			}

			try {
				await api.putProductsIgnore({ignore}, productIds)
			} finally {
				grid.value.refreshGrid();
				grid.value.resetItemsProgress();
			}
		};
		const updateItemIgnore = item => {
			const {productId: product_id, ignoreItem} = item;

			sendIgnoreProduct([product_id], !ignoreItem);
		};
		const batchActionIgnore = () => {
			const { gridItemsSelectedIds: selectedProduct } = grid.value.gridStore;

			sendIgnoreProduct(selectedProduct, true);
		};
		const batchActionNotIgnore = () => {
			const { gridItemsSelectedIds: selectedProduct } = grid.value.gridStore;

			sendIgnoreProduct(selectedProduct, false);
		};
		const showSetMarginDialog = () => {
			stateSetMargin.value = true;
			currentMargin.value = null;
		};
		const hideSetMarginDialog = () => {
			stateSetMargin.value = false;
		};
		const sendBatchMargin = async () => {
			const { gridItemsSelectedIds: selectedProduct } = grid.value.gridStore;

			progressSetMargin.value = true;

			try {
				await api.putSetMargin({margin_percent: currentMargin.value}, selectedProduct);

				Notify.create({
					message: `Margin was successfully update`,
					position: 'top',
					timeout: '2500',
					type: 'positive',
					actions: [{ icon: 'mdi-close', color: 'white' }]
				});

				hideSetMarginDialog();
			} finally {
				progressSetMargin.value = false;
			}
		};
		const initGridConfig = () => {
			const url = api.fetchProductsGrid();

			gridConfig.value = {
				api: { url, method: 'POST' },
				itemIdKey: 'productId',
				gridId: 'ProductsGrid',
				remoteFieldsConfig: true,
				pagination: {
					itemsPerPageOptions: [10, 50, 100, 500]
				},
				columns: [
					{
						title: 'Brand',
						field: 'manufacturerName',
						filter: {
							style: 'max-width: 155px;',
							type: 'select',
							optionValue: 'name',
							optionLabel: 'name',
							options: getLookupManufacturer()
						}
					},
					{
						title: 'Sku',
						field: 'model',
						filter: {
							style: 'min-width: 100px;',
						}
					},
					{
						title: 'Not In Crm',
						field: 'notInCrm',
						width: 110,
						filter: {
							type: 'select',
							style: 'max-width: 110px;',
							options: YesNoLookup
						}
					},
					{
						title: 'Specification',
						field: 'specification',
					},
					{
						title: 'Net Price',
						field: 'aqNetPrice',
						format: value => formatters.money(value),
						filter: {
							style: 'min-width: 115px;',
						}
					},
					{
						title: 'List Price ',
						field: 'aqListPrice',
						format: value => formatters.money(value),
						filter: {
							style: 'min-width: 115px;'
						}
					},
					{
						title: 'Map Price ',
						field: 'aqMapPrice',
						format: value => formatters.money(value),
						filter: {
							style: 'min-width: 115px;'
						}
					},
					{
						title: 'Product Category',
						field: 'productCategory',
						filter: {
							style: 'max-width: 135px;',
							type: 'textSelect',
							placeholder: 'Product',
							options: [
								{ label: 'Empty', value: 'Empty' },
								{ label: 'Not Empty', value: 'Not Empty' }
							]
						}
					},
					{
						title: 'Discount Category',
						field: 'discountCategoryName',
						filter: discountCategoryFilter
					},
					{
						title: 'Freeze Item',
						field: 'freezeItem',
						align: 'center',
						width: 80,
						filter: {
							style: 'max-width: 135px;',
							type: 'select',
							options: YesNoLookup
						}
					},
					{
						title: 'Ignore Item',
						field: 'ignoreItem',
						align: 'center',
						width: 80,
						filter: {
							style: 'max-width: 135px;',
							type: 'select',
							model: { label: 'No', value: false },
							options: YesNoLookup
						}
					},
					{
						title: 'Updated At',
						field: 'updatedAt',
						format: value => formatters.date(value),
						filter: {
							style: 'min-width: 155px;',
						}
					},
					{
						title: 'Error',
						field: 'error',
						filter: {
							style: 'max-width: 135px;',
							type: 'textSelect',
							placeholder: 'Error',
							options: [
								{ label: 'Empty', value: 'Empty' },
								{ label: 'Not Empty', value: 'Not Empty' }
							]
						}
					}
				]
			};
		};
		const getLookupManufacturer = () => {
			return manufacturersStore.manufacturers.map(manufacturer => ({
				label: manufacturer.name,
				value: manufacturer.name
			}))
		};
		const getLookupDiscountCategory = () => {
			return discountCategory.value.map(val => ({
				label: val,
				value: val
			}))
		};
		const discountCategoryFilter = ref({
			type: 'select',
			placeholder: 'Discount',
			style: 'max-width: 135px;',
			options: getLookupDiscountCategory()
		})
		const updateDiscountCategoryFilter = async (manufacturer) => {
			discountCategory.value = await api.fetchDiscountCategories(manufacturer ? {manufacturer} : {});
			discountCategoryFilter.value.options = getLookupDiscountCategory();
		};
		const changeFilter = async (event, name) => {
			const { value } = event;
			const model = name === 'ignoreItem' ? event : value;

			try {
				if (name === 'manufacturerName') {
					await updateDiscountCategoryFilter(value);
				}
			} finally {
				grid.value.setGridFilters({[name]: { model }});
			}
		};
		const clearFilter = async (column) => {
			column.filter.model = null;

			if (column.field === 'manufacturerName') {
				await updateDiscountCategoryFilter();
			}
		};

		const highlightGridItem = serviceId => {
			grid.value.highlightItems([serviceId]);
		};

		const init = async () => {
			iframeSyncClient.sendMessage({ type: 'loading', state: true });

			try {
				if (!manufacturersStore.manufacturers.length) {
					const manufacturers = await api.fetchManufacturers();

					manufacturersStore.updateManufacturers(manufacturers);
				}

				await updateDiscountCategoryFilter();

				initGridConfig();
			} finally {
				iframeSyncClient.sendMessage({ type: 'loading', state: false });
			}
		};

		expose({
			highlightGridItem
		});

		init();

		return {
			grid,
			gridConfig,
			stateAddToCrm,
			progressAddToCrm,
			discountCategory,
			discountCategoryFilter,
			YesNoLookup,
			stateSetMargin,
			progressSetMargin,
			currentMargin,
			sendBatchMargin,
			showSetMarginDialog,
			hideSetMarginDialog,
			getLookupDiscountCategory,
			changeFilter,
			clearFilter,
			getLookupManufacturer,
			showDialogAddToCrm,
			hideDialogAddToCrm,
			addToCrm,
			getSku,
			updateItemIgnore,
			batchActionIgnore,
			batchActionNotIgnore,
			sendIgnoreProduct,
			updateProduct
		};
	}
};
</script>
