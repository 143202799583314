import script from "./Main.vue?vue&type=script&setup=true&lang=js"
export * from "./Main.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QTabs,QRouteTab,QBtnDropdown,QList,QItem,QItemSection,QSpace,QResizeObserver});
