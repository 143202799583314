<template>
	<q-page>
		<Header v-bind="$data.$appHeader" ref="header"/>

		<div class="app-content">
			<div class="q-mt-sm">
				<router-view :key="$route.fullPath" />
			</div>
			<q-resize-observer @resize="onContentResize" />
		</div>
	</q-page>
</template>

<script setup>
import { inject, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import Header from '@components/app-header';
import api from '@/api';

const { $setAppHeader, $resetAppHeader } = inject('$app');
const iframeSyncClient = inject('iframeSyncClient');

const header = ref(null);

const props = defineProps({
	taskId: {
		type: String,
		required: true
	}
})
const onContentResize = (size) => {
	const { height } = size;
	const { clientHeight } = header.value.$el;
	const heightHeader = clientHeight || 56;
	const styles = { height: `${height + heightHeader}px` };

	iframeSyncClient.sendMessage({ type: 'style', styles });
};

const init = async () => {
	iframeSyncClient.sendMessage({ type: 'loading', state: true });

	try {
		const task = await api.fetchTask(props.taskId);

		$setAppHeader({
			title: task.mode,
			backLink: {name: 'Tasks'}
		});
	} finally {
		iframeSyncClient.sendMessage({ type: 'loading', state: false });
	}
}

init();


onBeforeRouteLeave((to, from, next) => {
	$resetAppHeader();

	next();
});

</script>
