<template>
	<tbody>
	<tr v-for="n in store.gridSkeletonCount" :key="n">
		<td v-if="store.gridItemSelectionEnable" class="selection-column-body">
			<q-skeleton size="20px" type="rect" class="q-mx-auto" />
		</td>
		<template v-if="!isMobile">
			<td v-for="column in store.gridColumns" :key="`skeleton-${column.name}-${n}`" :style="column.style"
				:class="`text-${column.titleAlign}`">
				<q-skeleton type="rect" />
			</td>
		</template>
		<td v-else colspan="2">
			<q-skeleton type="rect" />
		</td>
	</tr>
	</tbody>
</template>
<script setup>
import { inject } from 'vue';
import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);

const { isMobile } = store.platform;
</script>

<style lang="scss" scoped>
tbody {
	tr {
		td {
			vertical-align: initial;
			padding: 6px;

			&.selection-column-body {
				width: 50px;
				text-align: center;
			}
		}

	}

	&:first-of-type {
		tr {
			td {
				padding: 8px 6px 6px;
			}
		}
	}
}
</style>
