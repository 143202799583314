<template>
	<q-item :class="['crm-alert', {'crm-alert--outline': props.outline}, {'crm-alert--rounded': props.rounded}, bgClass, textColor]">
		<q-item-section v-if="!!props.icon || hasSlot('icon')" side>
			<slot name="icon">
				<q-icon :name="props.icon" :color="textColor" />
			</slot>
		</q-item-section>
		<q-item-section>
			<slot name="body">
				<q-item-label v-if="hasSlot('message') || props.message.length > 0"><slot name="message">{{ props.message }}</slot></q-item-label>
				<q-item-label
					v-if="hasSlot('caption') || props.caption.length > 0"
					caption
					:class="[bgClass, textColor]"
				>
					<slot name="caption">{{ props.caption }}</slot>
				</q-item-label>
			</slot>
		</q-item-section>
	</q-item>
</template>

<script setup>
import { useSlots, computed } from 'vue';

const props = defineProps({
	message: {
		type: String,
		default: ''
	},
	caption: {
		type: String,
		default: ''
	},
	icon: {
		type: [String, null],
		default: 'mdi-information-outline'
	},
	outline: {
		type: Boolean,
		default: false
	},
	rounded: {
		type: Boolean,
		default: false
	},
	color: { // Uses a color from the next color palette: https://quasar.dev/style/color-palette#using-as-css-classes
		type: [String, null],
		default: null
	},
	textColor: {
		type: String,
		default: ''
	}
});

const slots = useSlots();

const hasSlot = slotName => !!slots[slotName];

const bgClass = computed(() => `bg-${props.color}`);

</script>

<style lang="scss" scoped>
.crm-alert {
	--border-color: #e9e9e9;

	gap: 10px;
	padding: 16px;
	border-radius: 3px;

	:deep(.q-item__section--side) {
		padding-right: 0;
	}
}

.crm-alert--outline {
	border: 1px solid var(--border-color);
}

.crm-alert--rounded {
	border-radius: 28px;
}
</style>
