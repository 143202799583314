<template>
	<tr class="columns-head">
		<slot v-if="hasSlot('columnsRow')"
			  name="columnsRow"
			  :columns="store.gridColumns"
		></slot>
		<template v-else>
			<th v-if="store.gridItemSelectionEnable"
				:class="['selection-column-head', { 'sticky-column': store.isStickyColumn }]">
				<slot v-if="hasSlot('columnAllSelector')" name="columnAllSelector" :store="store"></slot>
				<template v-else>
					<q-spinner v-if="store.itemsLength && store.gridItemsAllSelectedProgress" color="primary"
							   size="24px" class="q-ma-sm" />
					<q-checkbox v-else v-model="store.gridItemsAllSelected"
								:disable="!store.itemsLength || !!store.state.gridError"
								indeterminate-value="partial" dense
								@update:model-value="triggerAllSelection"
					/>
				</template>
			</th>
			<template v-if="!mobileColumn">
				<th v-for="(column, idx) in store.gridColumns"
					:key="column.name"
					:style="column.style"
					:class="[`text-${column.titleAlign}`, {
					'cursor-pointer': column.sortable,
					'no-pointer-events': !store.itemsLength || !!store.state.gridError,
					'sticky-column': store.isStickyColumn && !idx,
					'has-selector': store.isStickyColumn && store.gridItemSelectionEnable
				}]"
					@click="triggerSort(column)"
				>
					<slot v-if="hasSlot(`column${column.name}`)"
						  :name="`column${column.name}`"
						  :value="column.title"
						  :column="column"
						  :index="idx"
					></slot>
					<template v-else>
						{{ column.title }}
						<q-icon v-if="column.sortable && column.sortOrder"
								:name="`mdi-sort-${ column.sortOrder === 'asc' ? 'ascending' : 'descending' }`"
								color="primary"
								size="1.4rem"
								:class="['column-sort-icon q-ma-sm', { 'left': column.titleAlign === 'right' || column.titleAlign === 'end' }]"
						/>
					</template>
				</th>
			</template>
			<template v-else>
				<th :style="mobileColumn.style"
					:class="[`text-${mobileColumn.titleAlign}`, {
						'cursor-pointer': mobileColumn.sortable,
						'no-pointer-events': !store.itemsLength || !!store.state.gridError,
						'has-selector': store.isStickyColumn && store.gridItemSelectionEnable
					}]"
					colspan="2"
					@click="triggerSort(mobileColumn)"
				>
					<slot v-if="hasSlot(`columnMobile${mobileColumn.name}`)"
						  :name="`columnMobile${column.name}`"
						  :value="mobileColumn.title"
						  :column="mobileColumn"
					></slot>
					<template v-else>
						{{ mobileColumn.title }}
						<q-icon v-if="mobileColumn.sortable && mobileColumn.sortOrder"
								:name="`mdi-sort-${ mobileColumn.sortOrder === 'asc' ? 'ascending' : 'descending' }`"
								color="primary"
								size="1.4rem"
								:class="['column-sort-icon q-ma-sm', { 'left': mobileColumn.titleAlign === 'right' || mobileColumn.titleAlign === 'end' }]"
						/>
					</template>
				</th>
			</template>
		</template>
	</tr>
</template>

<script setup>
import { useSlots, inject, computed } from 'vue';

import { useGridStore } from '../../store';
import { STORE_KEY } from '../../const';

const emit = defineEmits([ 'all-selected', 'sort' ]);

const storeName = inject(STORE_KEY);
const store = useGridStore(storeName);
const slots = useSlots();

const { isMobile } = store.platform;
const mobileColumn = computed(() => isMobile ? store.gridColumns.find(column => column) : null);

const hasSlot = slotName => !!slots[slotName];
const triggerAllSelection = event => emit('all-selected', event);
const triggerSort = column => emit('sort', column);
</script>

<style lang="scss" scoped>
tr {
	background-color: #f2f2f2;

	&:first-child {
		th {
			border-top: 1px solid #ddd;
		}
	}

	th {
		position: relative;
		padding: 6px;
		vertical-align: top;
		border-bottom: 1px solid #ddd;

		&.sticky-column {
			position: sticky;
			z-index: 1;
			left: 0;
			background-color: #f2f2f2;

			&.has-selector {
				left: 33px;
			}
		}

		&:not(:last-child) {
			border-right: 1px solid #ddd;
		}

		.column-sort-icon {
			position: absolute;
			top: 0;
			right: 0;

			&.left {
				left: 0;
			}
		}
	}
}
</style>
