<template>
	<div
		ref="appHeader"
		:class="['app-header', headerOptions.headerClass]"
		data-test="app-header"
		tabindex="-1"
		@keydown="arrowAction"
	>
		<q-toolbar class="q-px-md py-xsm toolbar">
			<slot name="prepend"></slot>

			<q-btn
				v-if="!!backLink"
				outline no-caps
				label="Back"
				:to="backLink"
				icon="mdi-arrow-left-thin"
				text-color="black"
				class="q-btn--outline-gray q-mr-sm"
			/>

			<q-img v-if="appIcon" :src="appIcon" :spinner-color="headerOptions.appIconSpinnerColor" class="app-icon" />

			<q-toolbar-title v-if="headerTitle">
				<slot name="prepend-title"></slot>
				<q-breadcrumbs
						:class="headerOptions.breadcrumbsClass"
						:active-color="headerOptions.breadcrumbsActiveColor"
						:separator-color="headerOptions.breadcrumbsSeparatorColor"
				>
					<q-breadcrumbs-el v-for="(props, idx) in headerTitle" v-bind="props" :key="idx" />
				</q-breadcrumbs>
				<slot name="append-title"></slot>
			</q-toolbar-title>

			<q-space />

			<slot name="append"></slot>
		</q-toolbar>
		<q-toolbar v-if="hasInsetSlot" inset>
			<slot name="inset"></slot>
		</q-toolbar>
	</div>
</template>

<script>
import { getType } from '@utils/type';

export const defaultHeaderOptions = {
	headerClass: 'bg-white text-primary',
	appIconSpinnerColor: 'primary',
	breadcrumbsClass: 'text-grey-9',
	breadcrumbsActiveColor: 'primary',
	breadcrumbsSeparatorColor: 'grey-5'
};

export default {
	name: 'AppHeader',
	props: {
		backLink: {
			type: [ String, Object, null ],
			default: null
		},
		appIcon: {
			type: [ String, null ],
			default: null
		},
		title: {
			type: [ String, Array, Object, null ],
			default: null
		},
		options: {
			type: [ Object, null ],
			default: null
		}
	},
	computed: {
		hasInsetSlot() {
			return !!this.$slots['inset'];
		},
		headerTitle() {
			const titleType = getType(this.title);
			const map = {
				array: () => this.title,
				object: () => [ { ...this.title } ],
				string: () => [ { label: this.title } ],
				null: () => null
			};

			return map[titleType]();
		},
		headerOptions() {
			return this.options ? { ...defaultHeaderOptions, ...this.options } : defaultHeaderOptions;
		}
	},
	mounted() {
		//if (this.$refs.appHeader) {
			//this.$refs.appHeader.$el.focus();
		//}
	},
	methods: {
		arrowAction({ code }) {
			if (code === 'Backspace') {
				this.$router.push(this.backLink);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.app-header {
	&:focus-visible {
		outline: none;
	}

	.toolbar {
		min-height: 56px;
	}
}

.app-icon {
	height: 32px;
	max-width: 32px
}

.py-xsm {
	padding-top: 10px;
	padding-bottom: 10px;
}
</style>
