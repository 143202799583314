import { defineStore } from 'pinia';

export const useLookupsStore = defineStore('lookups', {
	state: () => ({
		LogLevelLookup:  [
			{label: 'None', value: 'None', class: ''},
			{label: 'Trace', value: 'Trace', class: 'grid-item-log-level-trace'},
			{label: 'Debug', value: 'Debug', class: 'grid-item-log-level-debug'},
			{label: 'Information', value: 'Information', class: ''},
			{label: 'Warning', value: 'Warning', class: 'grid-item-log-level-warning'},
			{label: 'Error', value: 'Error', class: 'grid-item-log-level-error'},
			{label: 'Critical', value: 'Critical', class: 'grid-item-log-level-critical'}
		],
		taskStatuses: []
	}),
	actions: {
		updateTaskStatuses(taskStatuses) {
			this.taskStatuses = taskStatuses;
		}
	}
});
